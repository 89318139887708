import { db } from './localbase'

export async function getOfflineDataCapture() {
    try {
        const data = await db.offlineDataCapture.toArray()
        return data;
    } catch (ex) {}
}

export async function deleteEntry(id: any) {
    try {
        const data = await db.offlineDataCapture.delete(id)
        return data;
    } catch (ex) {}
}
