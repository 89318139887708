import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import GenericLayout from '../../components/GenericLayout'
import { useQuery } from 'react-query'
import { BackArrow } from '../../components/Icons/BackArrow'
import { ClientInfo } from '../../components/Invoice/NewInvoice/ClientInfo'
import { ServiceRendered } from '../../components/DataCapture/GroundHandling/Scheduled/ServiceRendered'
import { ServicesRendered } from '../../components/Invoice/NewInvoice/ServicesRendered/ServiceRendered'

export default function CreateNewInvoice(): ReactElement {
    const navigate = useNavigate()
    const [formStep, setFormStep] = useState(1)

    return (
        <GenericLayout>
            <div className="flex flex-col items-center md:items-start mb-8">
                <div className="w-full h-32 bg-[#023E7D]">
                    <div className="flex items-center justify-end pr-4 pt-4 md:pr-16 md:pt-6">
                        <div
                            className="flex gap-2 cursor-pointer items-center border border-white rounded-lg p-2"
                            onClick={() => navigate('/invoice')}
                        >
                            <BackArrow className="stroke-white w-4" />
                            <p className="text-white md:text-base">Back</p>
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded-md -mt-12 md:mx-auto w-[calc(100%-30px)] md:w-[calc(80%-30px)] shadow-md p-4 md:py-8 md:px-10">
                    <p className="font-bold md:text-2xl text-[#002555] font-Inter">
                        Create Invoice
                    </p>
                    <div className="mt-6">
                        <div className="flex justify-center">
                            <div
                                className={`border-b-4 border-[#023E7D] w-1/3 flex items-center gap-2 pb-1 cursor-pointer`}
                                onClick={() => setFormStep(1)}
                            >
                                <p className="w-5 h-5 rounded-full font-Inter border border-[#023E7D] flex justify-center items-center text-[8px] md:text-[10px] text-[#023E7D] font-medium">
                                    1
                                </p>
                                <p className="text-[#023E7D] text-xs md:text-base font-Inter font-medium">{`Client Info`}</p>
                            </div>
                            <div
                                className={`${
                                    formStep >= 2
                                        ? 'border-b-4 border-[#023E7D]'
                                        : 'border-b border-[#cc]'
                                }  w-1/3 flex items-center gap-2 pb-1 justify-center cursor-pointer`}
                    
                                onClick={() => setFormStep(2)}
                            >
                                <p
                                    className={`w-5 h-5 rounded-full ${
                                        formStep >= 2
                                            ? 'text-[#023E7D] border border-[#023E7D]'
                                            : 'text-[#333333] border'
                                    } flex justify-center items-center text-[8px] md:text-[10px] font-Inter font-medium`}
                                >
                                    2
                                </p>
                                <p
                                    className={`${
                                        formStep >= 2
                                            ? 'text-[#023E7D]'
                                            : 'text-[#333333]'
                                    } font-Inter text-xs md:text-base font-medium`}
                                >{`Services Rendered`}</p>
                            </div>
                        </div>
                        {formStep === 1 && (
                            <ClientInfo setFormStep={setFormStep} />
                        )}
                        {formStep === 2 && (
                            <ServicesRendered setFormStep={setFormStep} toggleSettlement={()=>{}} />
                        )}
                    </div>
                </div>
            </div>
        </GenericLayout>
    )
}
