import React, { Dispatch } from 'react'
import { toast } from 'react-toastify'
import { ButtonOutlined } from '../../UIKit/ButtonOutlined/Button'
import { ButtonPrimary } from '../../UIKit/ButtonPrimary/Button'
import { Input } from '../../UIKit/Input/Input'
import { AppModal } from '../../UserSettings/Modal'

type EditReasonProps = {
    comment?: string
    setComment: Dispatch<React.SetStateAction<string>>
    setOpenEditAmountModal: Dispatch<React.SetStateAction<boolean>>
    setShowConfirmation: Dispatch<React.SetStateAction<boolean>>
    openEditAmountModal: boolean
    amount: number
    lineItem: any
    setLineItem: Dispatch<React.SetStateAction<any>>
    selectedntryDetail?: any
    setSelectedntryDetail?: any
    setConfirmType: any
}
const EditAmountModal = (props: EditReasonProps) => {
    const {
        comment,
        setComment,
        setOpenEditAmountModal,
        openEditAmountModal,
        setShowConfirmation,
        lineItem,
        amount,
        setLineItem,
        selectedntryDetail,
        setSelectedntryDetail,
        setConfirmType
    } = props

    console.log('SS:', { selectedntryDetail })
    return (
        <AppModal
            title=""
            isOpen={openEditAmountModal}
            setIsOpen={setOpenEditAmountModal}
            containerClass="w-full   max-w-2xl"
            contentClass="h-auto rounded-lg lineItems-center"
            showCloseButton={false}
            childrenContainer="w-full rounded-lg flex flex-col p-6"
        >
            {' '}
            <div className="flex justify-between">
                <p className="font-Inter font-bold text-[#5B5C60]  text-[18px]">
                    Edit Rate
                </p>
                <span
                    className="font-bold text-[#ccc] cursor-pointer"
                    onClick={() => setOpenEditAmountModal(false)}
                >
                    X
                </span>
            </div>
            <div className="flex flex-col mt-3">
                <label htmlFor="" className="text-[#787885] mb-1">
                    {' '}
                    Enter New Rate
                </label>
                <input
                    type="number"
                    placeholder=""
                    value={lineItem.amount}
                    className="h-[55px] rounded-md bg-[#F9F9F9] w-full border-0 py-4 text-[13px] placeholder:text-[13px] "
                    onChange={(e) => {
                        if (e?.target?.value === '0') {
                            toast.error('Value cannot be 0')
                            return
                        }
                        console.log({ lineItem })

                        setLineItem({
                            ...lineItem,
                            amount: e?.target?.value,
                        })
                        setSelectedntryDetail({
                            ...selectedntryDetail,
                            metadata: selectedntryDetail.metadata.map(
                                (item: any, index: number) => {
                                    let curItem =
                                        selectedntryDetail?.metadata[
                                            lineItem?.index
                                        ]
                                    if (
                                        curItem?.serviceType ===
                                        item?.serviceType
                                    ) {
                                        return {
                                            ...item,
                                            amount: e?.target?.value,
                                        }
                                    } else return item
                                }
                            ),
                        })
                    }}
                />
            </div>
            <div className="flex flex-col my-5">
                <label htmlFor="" className="text-[#787885] mb-1">
                    Reason for Changing Rate
                </label>
                <textarea
                    placeholder=" "
                    className="h-[99px] border-0 rounded-md bg-[#F9F9F9]"
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                />
            </div>
            <div className="mb-4 gap-6 flex justify-between">
                <ButtonOutlined
                    isLoading={false}
                    type="button"
                    label="Back"
                    handleClick={() => {
                        // setShowReason(true)
                        setOpenEditAmountModal(false)
                    }}
                />
                <ButtonPrimary
                    isLoading={false}
                    type="button"
                    label="Save"
                    handleClick={() => {
                        if ((comment?.length as number) < 4) {
                            toast('Comment cannot be less than 4 characters')
                            return
                        } else if (!amount || amount < 0) {
                        }
                        setOpenEditAmountModal(false)

                        // setShowConfirmation(true)
                        // setConfirmType('edit')
                    }}
                />
            </div>
        </AppModal>
    )
}

export default EditAmountModal
