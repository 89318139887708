import { getOperationType } from '../services/settings'
import { db } from './localbase'

export async function setupOperationTypes() {
    //fetch and persist operation types
    const operationTypeResponse = await getOperationType({})
    if (operationTypeResponse.data) {
        await addOperationTypes(operationTypeResponse.data)
    }
}

export async function addOperationTypes(data: any) {
    try {
        await db.operationTypes.bulkAdd(data)
    } catch (ex) {}
}
