import { devInstance } from '../axios/axiosInstance'

export const transactionHistory = async (payload: any) => {
    const data = await devInstance.post('/Dashboard/TransactionHistory', payload)
    return data
}

export const reconcileInternal = async (payload: any) => {
    const data = await devInstance.post('/DataReconciliation/ReconcileDataEntryInternal', payload)
    return data
}

export const airlineAcceptEntry = async (payload: any) => {
    const data = await devInstance.post('/DataReconciliation/AcceptDataEntryOneWay', payload)
    return data
}
export const updateBillAmount = async (payload: any) => {
    const data = await devInstance.post('/DataReconciliation/UpdateAmountForBill', payload)
    return data
}
export const cancelBillUpdate = async (payload: any) => {
    const data = await devInstance.post('/DataReconciliation/CancelBillUpdate', payload)
    return data
}
export const discardDataEntryFromBill = async (payload: any) => {
    const data = await devInstance.post('/DataReconciliation/DiscardDataEntryFromBill', payload)
    return data
}
export const treatBillUpdate = async (payload: any) => {
    const data = await devInstance.post('/DataReconciliation/TreatBillUpdate', payload)
    return data
}
 

export const airlineDeclinetEntry = async (payload: any) => {
    const data = await devInstance.post('/DataReconciliation/DisputeDataEntryOneWay', payload)
    return data
}

export const reconcileExternalOneWay = async (payload: any) => {
    const data = await devInstance.post('/DataReconciliation/ReconcileDataEntryExternalOneWay', payload)
    return data
}
