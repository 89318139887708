import { ReactElement, useState } from 'react'
import GenericLayout from '../../components/GenericLayout'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getSavedState } from '../../utils/localStorage'
import { useMutation, useQuery } from 'react-query'
import { getSingleEntry } from '../../utils/services/dataEntry'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import {
    airlineAcceptEntry,
    reconcileInternal,
    airlineDeclinetEntry,
} from '../../utils/services/transaction'
import ReasonModal from '../../components/TransactionEmail/ReasonModal/ReasonModal'
import DetailsModal from '../../components/TransactionEmail/DetailsModal/DetailsModal'
import DisputeTypeModal from '../../components/TransactionEmail/DisputeTypeModal/DisputeTypeModal'
import TransactionEmailSuccess from '../../components/TransactionEmail/Success/Success'
import Confirm from '../../components/TransactionEmail/Confirm/Confirm'
export default function ApproveViaMail(): ReactElement {
    const userToken = getSavedState('GHOSTUser') || {}
    const { userRole, organizationTypeId } = userToken
    const [entryDetail, setEntryDetail] = useState<any>({})
    const [showDetailsModal, setShowDetailsModal] = useState(true)

    //Reason
    const [showReason, setShowReason] = useState(false)
    const [reasonText, setreasonText] = useState('')

    //Dispute Type
    const [showDisputeTypeModal, setShowDisputeTypeModal] = useState(false)
    const [selectedDisputeType, setSelectedDisputetype] = useState()

    //success
    const [showSuccess, setShowSuccess] = useState(false)

    //approval
    const [showApproveModal, setShowApproveModal] = useState(false)

    const { id } = useParams()
    const navigate = useNavigate()

    const handleSuccess = () => {
        setShowDisputeTypeModal(false)
        setShowReason(false)
        setShowApproveModal(false)
        setShowDetailsModal(false)
        setShowSuccess(true)
    }

    const { isLoading } = useQuery(['EntrySearch'], () => getSingleEntry(id), {
        enabled: true,
        retry: false,
        onSuccess: (res) => {
            setEntryDetail(res?.data)
        },
        onError: (err) => {
            toast.error('unable to retrieve entry details')
        },
    })

    const { mutateAsync: declineAsync, isLoading: isloadingDecline } =
        useMutation(reconcileInternal, {
            onSuccess: () => {
                handleSuccess()
            },
            onError: (error: AxiosError) => {
                toast.error(error?.response?.data?.errors[0])
            },
        })

    const { mutateAsync: airlineDeclineAsync, isLoading: airlineDeclining } =
        useMutation(airlineDeclinetEntry, {
            onSuccess: ({ data }) => {
                handleSuccess()
            },
            onError: (error: AxiosError) => {
                toast.error(error?.response?.data?.errors[0])
            },
        })

    const { mutateAsync: airlineAcceptAsync, isLoading: airlineAccepting } =
        useMutation(airlineAcceptEntry, {
            onSuccess: () => {
               handleSuccess()
            },
            onError: (error: AxiosError) => {
                toast.error(error?.response?.data?.errors[0])
            },
        })

    const {
        mutateAsync: reconcileInternally,
        isLoading: reconcileInternalLoading,
    } = useMutation(reconcileInternal, {
        onSuccess: () => {
            handleSuccess()
        },
        onError: (error: AxiosError) => {
            toast.error(error?.response?.data?.errors[0])
        },
    })

    const acceptHandler = async () => {
        if (organizationTypeId === 2) {
            reconcileInternally({
                id: entryDetail?.id,
                status: 1,
            })
        } else {
            airlineAcceptAsync({
                id: entryDetail?.id,
                status: 3,
            })
        }
    }

    const declineHandler = async () => {
        if (reasonText.length < 4) {
            toast('Comment cannot be less than 4 characters')
            return
        }

        if (organizationTypeId === 2) {
            await declineAsync({
                id: entryDetail?.id,
                status: 2,
                comment: reasonText,
            })
            return
        }
        airlineDeclineAsync({
            id: entryDetail?.id,
            disputeType: selectedDisputeType,
            comment: reasonText,
        })
    }

    const grandTotal = entryDetail?.metadata?.reduce((acc: any, item: any) => {
        acc = acc + parseInt(item?.amount)
        return acc
    }, 0)

    const closeApproval = () => {
        navigate('/transactions')
    }

    return (
        <GenericLayout
            breadcrumbs={[<Link to="/audit">Transaction Email</Link>]}
        >
            <div className="p-6 bg-[#F1F2F3]">
                <p className="font-bold text-2xl text-[#002555]">Email</p>
                <DetailsModal
                    setShowDetailsModal={setShowDetailsModal}
                    setShowReason={setShowReason}
                    closeApproval={closeApproval}
                    entryDetail={entryDetail}
                    grandTotal={grandTotal}
                    isLoading={isLoading}
                    showDetailsModal={showDetailsModal}
                    setShowDisputeTypeModal={setShowDisputeTypeModal}
                    setShowApproveModal={setShowApproveModal}
                />
                <ReasonModal
                    reasonText={reasonText}
                    setShowReason={setShowReason}
                    setreasonText={setreasonText}
                    showReason={showReason}
                    setShowDetailsModal={setShowDetailsModal}
                    declineAsync={declineAsync}
                    airlineDeclineAsync={airlineDeclineAsync}
                    isloadingDecline={isloadingDecline || airlineDeclining}
                    entryDetail={entryDetail}
                    selectedDisputeType={selectedDisputeType}
                    declineHandler={declineHandler}
                />
                <DisputeTypeModal
                    showDisputeTypeModal={showDisputeTypeModal}
                    selectedDisputeType={selectedDisputeType}
                    setShowDisputeTypeModal={setShowDisputeTypeModal}
                    setSelectedDisputetype={setSelectedDisputetype}
                    setShowReason={setShowReason}
                    setShowDetailsModal={setShowDetailsModal}
                />

                <TransactionEmailSuccess
                    setShowSuccess={setShowSuccess}
                    showSuccess={showSuccess}
                    closeApproval={closeApproval}
                />
                <Confirm
                    showApproveModal={showApproveModal}
                    setShowApproveModal={setShowApproveModal}
                    setShowDetailsModal={setShowDetailsModal}
                    acceptHandler={acceptHandler}
                    airlineAccepting={
                        airlineAccepting || reconcileInternalLoading
                    }
                />
            </div>
        </GenericLayout>
    )
}
