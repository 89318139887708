import React from 'react'
import { ButtonOutlined } from '../../UIKit/ButtonOutlined/Button'
import { ButtonPrimary } from '../../UIKit/ButtonPrimary/Button'
import { AppModal } from '../../UserSettings/Modal'
import approveIcon from '../../../assets/img/TransactionHistory/approval.svg'
import { EditModalProps } from './EnterReasonModal'
import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import {
    cancelBillUpdate,
    discardDataEntryFromBill,
    treatBillUpdate,
    updateBillAmount,
} from '../../../utils/services/transaction'

const ConfrimationModal = (props: EditModalProps) => {
    const {
        setShowReason,
        comment,
        showConfirmation,
        setShowConfirmation,
        setOpenEditAmountModal,
        confirmType,
        selectedntryDetail,
        setEntryDetail,
        setShowSuccess,
        refetch,
    } = props

    const {
        mutateAsync: updateBillAsync,
        isLoading: updateBillingLoading,
        isError: updateBillError,
    } = useMutation(updateBillAmount, {
        onSuccess: ({ data }) => {
            // setEntryDetail(false)
            refetch()
            // @ts-
            setOpenEditAmountModal(false)
            toast('Successfully sent to your supervisor for approval')
            setShowSuccess(true)
            setEntryDetail(false)
            // setEntryDetail(false)
        },
        onError: (error: AxiosError) => {
            console.log('err', error?.response?.data[0])
            setShowConfirmation(false)
            toast.error(error?.response?.data[0])
        },
    })
    const {
        mutateAsync: cancelBillUpdateAsync,
        isLoading: cancelBillUpdateLoading,
        isError: cancelBillUpdateError,
    } = useMutation(cancelBillUpdate, {
        onSuccess: ({ data }) => {
            // setEntryDetail(false)
            refetch()
            // @ts-
            setOpenEditAmountModal(false)
            toast('Cancellation Successful!')
            setShowSuccess(true)
            setEntryDetail(false)
        },
        onError: (error: AxiosError) => {
            console.log('err', error?.response?.data[0])
            setShowConfirmation(false)
            toast.error(error?.response?.data[0])
        },
    })
    const {
        mutateAsync: deleteBillAsync,
        isLoading: deleteBillingLoading,
        isError: deleteBillError,
    } = useMutation(discardDataEntryFromBill, {
        onSuccess: ({ data }) => {
            // setEntryDetail(false)
            refetch()
            // @ts-
            setShowSuccess(true)
            setShowReason(false)
            setShowConfirmation(false)
            setEntryDetail(false)
            toast('Record deleted')
        },
        onError: (error: AxiosError) => {
            setShowConfirmation(false)
            console.log('err', error?.response?.data[0])

            toast.error(error?.response?.data[0])
        },
    })
    const {
        mutateAsync: treatBillUpdateAsync,
        isLoading: treatBillUpdateLoading,
        isError: treatBillUpdateError,
    } = useMutation(treatBillUpdate, {
        onSuccess: ({ data }) => {
            // setEntryDetail(false)
            refetch()
            // @ts-
            setShowSuccess(true)
            setShowConfirmation(false)
            setEntryDetail(false)
            toast('Success')
        },
        onError: (error: AxiosError) => {
            setShowConfirmation(false)
            console.log('err', error?.response?.data[0])

            toast.error(error?.response?.data[0])
        },
    })
    const getConfirmTypeText = () => {
        switch (confirmType) {
            case 'remove':
                return 'Kindly confirm you want to remove record'
            case 'decline':
                return 'Kindly confirm you want to decline record'
            case 'approve':
                return 'Kindly confirm  you want to approve record'

            case 'cancel':
                return 'Kindly confirm  you want to cancel updated record !'

            default:
                return 'Kindly confirm you want to update amount'
        }
    }
    return (
        <AppModal
            title=""
            isOpen={showConfirmation !== null}
            setIsOpen={setShowConfirmation}
            containerClass="w-full  md:w-[400px]"
            contentClass="h-auto rounded-lg items-center"
            showCloseButton={false}
            childrenContainer="w-full rounded-lg flex flex-col items-center"
        >
            <div className="bg-[#EFEFEF] w-full flex justify-center items-center h-12">
                <p className="font-bold font-Inter text-[#79828B]">Dialog</p>
            </div>
            <div className="flex flex-col items-center my-8">
                <img src={approveIcon} alt="success" />
                <p className="mt-4 font-Inter font-bold px-12 text-center text-lg">
                    Kindly Confirm Action
                </p>
                <p className="font-Inter mt-2 mb-8 px-16 text-center text-[#787885] text-sm">
                    {getConfirmTypeText()}
                </p>
            </div>
            <div className="mb-8 gap-6 flex justify-between">
                <ButtonOutlined
                    isLoading={false}
                    type="button"
                    label="Cancel"
                    handleClick={() => {
                        // Set all state variable to default
                        setShowReason(false)
                        setOpenEditAmountModal(false)
                        setShowConfirmation(false)
                        setEntryDetail(false)
                    }}
                />
                <ButtonPrimary
                    isLoading={
                        updateBillingLoading ||
                        deleteBillingLoading ||
                        treatBillUpdateLoading
                    }
                    type="button"
                    label="Confirm"
                    handleClick={() => {
                        // check login user and make api call
                        if (confirmType === 'remove') {
                            deleteBillAsync({
                                id: selectedntryDetail?.id,
                                comment: comment,

                                // fileUrl: "string"
                            })
                        } else if (confirmType === 'edit') {
                            updateBillAsync({
                                id: selectedntryDetail?.id,

                                comment: comment,
                                lineItems: selectedntryDetail.metadata.map(
                                    (item: any) => ({
                                        serviceType: item.serviceType,
                                        amount: item.amount,
                                    })
                                ),

                                // fileUrl: "string"
                            })
                        } else if (confirmType === 'approve') {
                            treatBillUpdateAsync({
                                id: selectedntryDetail?.id,
                                status: true,

                                // fileUrl: "string"
                            })
                        } else if (confirmType === 'decline') {
                            treatBillUpdateAsync({
                                id: selectedntryDetail?.id,
                                status: false,

                                // fileUrl: "string"
                            })
                        } else {
                            cancelBillUpdateAsync({
                                id: selectedntryDetail?.id,
                                comment: comment,

                                // fileUrl: "string"
                            })
                        }
                    }}
                />
            </div>
        </AppModal>
    )
}

export default ConfrimationModal
