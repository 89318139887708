import { FC } from 'react'
import { AppModal } from '../../../components/UserSettings/Modal'

type DisputeTypeModalT = {
    showDisputeTypeModal: any
    selectedDisputeType: any
    setShowDisputeTypeModal: any
    setSelectedDisputetype: any
    setShowDetailsModal: any
    setShowReason: any
}

const DisputeTypeModal: FC<DisputeTypeModalT> = (props) => {
    const {
        selectedDisputeType,
        setSelectedDisputetype,
        setShowDisputeTypeModal,
        showDisputeTypeModal,
        setShowDetailsModal,
        setShowReason,
    } = props

    const modalCloseHandler = () => {
        setShowDisputeTypeModal(false)
    }

    return (
        <AppModal
            title=""
            isOpen={showDisputeTypeModal}
            setIsOpen={modalCloseHandler}
            containerClass="w-full sm:w-80 lg:w-[400px]"
            contentClass="h-auto rounded-lg items-center"
            showCloseButton={false}
            childrenContainer="w-full rounded-lg flex flex-col"
        >
            <div className="flex flex-col my-8 px-6">
                <div className="flex justify-between items-center">
                    <p className="font-Inter font-bold text-[#6f6d6d]">
                        Reason for Reconcilliation
                    </p>
                    <span
                        className="font-bold text-[#ccc] cursor-pointer"
                        onClick={modalCloseHandler}
                    >
                        X
                    </span>
                </div>
                <div
                    className="mt-4 w-full py-3 border text-[#1B5BBC] border-[#1B5BBC] hover:bg-[#1B5BBC] hover:text-white rounded-md text-center font-bold cursor-pointer"
                    onClick={() => {
                        setSelectedDisputetype(1)
                        setShowDisputeTypeModal(false)
                        setShowDetailsModal(false)
                        setShowReason(true)
                    }}
                >
                    Inaccurate data
                </div>
                <div
                    className="mt-4 w-full py-3 border text-[#1B5BBC] border-[#1B5BBC] hover:bg-[#1B5BBC] hover:text-white rounded-md text-center font-bold cursor-pointer"
                    onClick={() => {
                        setSelectedDisputetype(2)
                        setShowDisputeTypeModal(false)
                        setShowDetailsModal(false)
                        setShowReason(true)
                    }}
                >
                    Invalid transaction
                </div>
            </div>
        </AppModal>
    )
}

export default DisputeTypeModal
