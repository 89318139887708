import { ReactElement, lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
const Login = lazy(() => import('../../pages/Login/Login'))
const ActivateAccount = lazy(
    () => import('../../pages/Login/ActivateAccount/ActivateAccount')
)
const InitiatePasswordReset = lazy(
    () =>
        import('../../pages/Login/InitiatePasswordReset/InitiatePasswordReset')
)
const ResetPassword = lazy(
    () => import('../../pages/Login/ResetPassword/ResetPassword')
)
const Playground = lazy(() => import('../../pages/Playground/Playground'))
import { NotFound } from '../../pages/NotFound/NotFound'
import { UiProvider } from '../../context/uiContext'
import { ToastContainer } from 'react-toastify'
import PermissionRoutes from './Routes/PermissionRoutes'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LandingBg } from '../../components/LandingBg/LandingBg'
import DataEntry from '../../pages/DataCapture/DataEntry/DataEntry'

function App(): ReactElement {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <UiProvider>
                <BrowserRouter>
                    <Suspense fallback={<LandingBg />}>
                        <Routes>
                            <Route path="/" element={<Login />} />
                            <Route
                                path="/initiate-password-reset"
                                element={<InitiatePasswordReset />}
                            />
                            <Route
                                path="/resetpassword"
                                element={<ResetPassword />}
                            />
                            <Route
                                path="/activate"
                                element={<ActivateAccount />}
                            />
                            <Route path="/*" element={<PermissionRoutes />} />
                            <Route path="/data-cature-offline" element={<DataEntry />} />

                            <Route path="playground" element={<Playground />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Suspense>
                </BrowserRouter>
                <ToastContainer className="p-3 w-fit text-sm" />
            </UiProvider>
        </LocalizationProvider>
    )
}
export default App
