import { useState } from 'react'
import { useQuery } from 'react-query'
import { fetchCurrency } from '../utils/services/utils'
type Currency = {
    createdOn: string
    id: number
    isoCode: string
    name: string
    updatedOn: string
}
export default function useCurrency() {
    const [currency, setCurrency] = useState<Currency[]>([])

    const payload = {
        pageSize: 20,
        from: 1,
        parameter: {},
    }

    const { refetch } = useQuery(
        ['GetAllCurrencies'],
        () => fetchCurrency(payload),
        {
            enabled: true,
            retry: 2,
            onSuccess: (response) => {
                setCurrency(response.data.items)
            },
        }
    )

    const getAllCurrencies = () => {
        refetch()
    }

    return { currency, getAllCurrencies }
}
