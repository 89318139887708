import { useState } from 'react'
import { useQuery } from 'react-query'
import { getLocationByOrganizationId } from '../utils/services//settings/organization'

export default function useLocationByOrgId(payload:number) {
    const [locations, setLocations] = useState([])

    const { refetch } = useQuery(['GetLocByOrgId'], () => getLocationByOrganizationId(payload), {
        enabled: true,
        retry: 2,
        onSuccess: (response) => {
            setLocations(response.data)
        },
    })

    const getLocByOrgId = () => {
        refetch();
    }

    return { locations, getLocByOrgId }
}
