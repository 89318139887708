import { AxiosError, AxiosResponse } from 'axios'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BackArrow } from '../../components/Icons/BackArrow'
import { ButtonOutlined } from '../../components/UIKit/ButtonOutlined/Button'
import { ButtonPrimary } from '../../components/UIKit/ButtonPrimary/Button'
import { formatNumber } from '../../utils/formatNumber'
import { createInvoice } from '../../utils/services/invoice'

export default function PreviewInvoice({
    setIsOpen,
    invoiceInfo,
    payload
}: {
    setIsOpen: any
    invoiceInfo: any
    payload:any
}) {
    const navigate = useNavigate()

    const { mutateAsync, isLoading, isError, error, data } = useMutation(
        createInvoice,
        {
            onSuccess: (response) => {
                toast.success(`${response?.data?.message || 'Invoice Created Successfully'}`)
                navigate('/invoice')
            },
            onError: (error: AxiosError) => {
                toast.error(error?.response?.data?.errors[0])
            },
        }
    )

    const onSubmit = () => {
        mutateAsync({
            ...payload,
            
            isPreview: false,
            
        })
    }

    const handleBack = () => {
        setIsOpen(false)
    }

    return (
        <div className="flex flex-col items-center overflow-y-scroll  h-[90vh] mb-8 ">
            
            <main >
                <div className="flex flex-col justify-between p-4 items-center border-b">
                    <div className="flex justify-between w-full">
                        <p className="text-[#C5C5C5] font-semibold text-xs  capitalize">
                            Invoice no:{' '}
                            <span className="text-md font-Inter text-black ">
                                {' '}
                                {invoiceInfo?.invoiceNumber || '-------'}
                            </span>
                        </p>
                        <p className="text-[#C5C5C5] font-semibold text-xs capitalize">
                            Invoice Date:{' '}
                            <span className="text-md font-Inter  text-black ">
                                {' '}
                                {moment(invoiceInfo?.createdOn).format('DD/MM/yyyy')}
                            </span>
                        </p>

                        {/* <span
                    className="text-[#24292E] text-lg cursor-pointer"
                    onClick={() => {
                        setIsOpen(false)
                    }}
                >
                    x
                </span> */}
                    </div>
                    <div className="flex justify-center  mt-10 ">
                        {invoiceInfo.organizationLogo ? (
                            <img
                                src={invoiceInfo?.organizationLogo}
                                alt=""
                                width={100}
                            />
                        ) : (
                            <p>{invoiceInfo?.organizationName}</p>
                        )}
                    </div>
                    <p className="text-[#DE7839]  mt-2 ">Invoice to</p>
                    <h3 className="text-[20px] mt-2 font-semibold">
                        {invoiceInfo?.clientName}
                    </h3>
                    <p className="text-[#C5C5C5] text-xs mt-4">Description:</p>
                    <p className="text-sm font-semibold mt-2">
                        {invoiceInfo?.description}
                    </p>
                </div>
                <article className=" p-8 text-[#5B5C60] flex justify-between bg-[#EFEFEF]">
                    <div className="">
                        <p className="">
                            Duration:
                            <span className="font-semibold block">
                                {invoiceInfo?.duration || '--------'}
                            </span>
                        </p>
                    </div>
                    <div>
                        <p className="">
                            Address:
                            <span className="font-semibold block">
                                {invoiceInfo?.clientAddress}
                            </span>
                        </p>
                    </div>
                </article>
                <section>
                    <div className="overflow-auto max-h-[70vh]  scrollbar-hide max-w-full  ">
                        <table className="min-w-full text-center">
                            <thead className="bg-[#EB9E72] sticky top-0">
                                <tr className="">
                                    <th
                                        scope="col"
                                        className="text-xs font-medium text-white p-4"
                                    >
                                        S/N
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-xs font-medium text-white p-4"
                                    >
                                        TRANSACTION DATE
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-xs font-medium text-white p-4"
                                    >
                                        FLIGHT NUMBER
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-xs font-medium text-white p-4"
                                    >
                                        GHCN NO
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-xs font-medium text-white p-4"
                                    >
                                        STATION
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-xs font-medium text-white px-6 py-4"
                                    >
                                        AMOUNT
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-white px-6 py-4"
                                    >
                                        VAT
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-sm font-medium text-white px-6 py-4"
                                    >
                                        TOTAL
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoiceInfo?.invoiceItems &&
                                    invoiceInfo?.invoiceItems?.map(
                                        (item:any, index:number) => (
                                            <tr>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {index + 1}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {moment(
                                                        item?.transactionDate
                                                    ).format('DD/MM/YY')}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {item?.flightNumber}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {
                                                        item?.contractDataReferenceNumber
                                                    }
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {item?.location}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {`₦${formatNumber(
                                                        item?.amount
                                                    )}`}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {`₦${formatNumber(
                                                        item?.vat
                                                    )}`}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {`₦${formatNumber(
                                                        item?.total
                                                    )}`}
                                                </td>
                                            </tr>
                                        )
                                    )}
                            </tbody>
                        </table>
                    </div>
                </section>

                <footer>
                    <div className="flex flex-col justify-end items-end p-5 bg-[#fbfbfb]">
                        <p>
                            {' '}
                            <span className=" text-sm text-[#AEAEAE] mr-3">
                                SUB TOTAL:{' '}
                            </span>{' '}
                            {`₦${formatNumber(invoiceInfo?.subTotal!)}`}
                        </p>
                        <p>
                            {' '}
                            <span className="text-sm text-[#AEAEAE] mr-3">
                                VAT:{' '}
                            </span>
                            {`₦${formatNumber(invoiceInfo?.vat!)}`}
                        </p>
                    </div>
                    <div className="bg-[#FCF2EB] flex justify-end items-end py-3 px-5">
                        <p className="">
                            <span className=" text-[#DE7839] mr-3 ">
                                Grand Total:{' '}
                            </span>
                            {`₦${formatNumber(invoiceInfo?.grandTotal!)}`}
                        </p>
                    </div>
                </footer>
            </main>
             
             <div className="flex justify-end   gap-4 m-5 ">
             <ButtonOutlined
                        disabled={isLoading}
                        label="Back"
                        handleClick={() => handleBack()}
                    />
                 <ButtonPrimary
                        label="Ok"
                        handleClick={onSubmit}
                        isLoading={isLoading}
                    />
            </div>
        </div>
    )
}
