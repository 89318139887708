import { FC } from 'react'

export const Avatar: FC<{ image: any; name: string; label: string }> = ({
    image,
    name,
    label,
}) => {
    return (
        <div className='w-1/3'>
            <p className="text-[#AEAEAE] uppercase text-xs mb-1">{label}:</p>
            <div className="flex gap-2 items-center">
                <div className="h-10 w-10 bg-white rounded-full border border-[#ccc] overflow-hidden">
                    <img src={image} alt="avatar" className="w-full h-full" />
                </div>
                <p className="text-xs font-Inter font-bold">{name}</p>
            </div>
        </div>
    )
}
