import moment from 'moment-timezone'
import { FC } from 'react'
import avatarIcon from '../../../assets/img/DataReconcilliation/avatar.svg'

interface ICardData {
    comment: string
    createdOn: string
    id: number
    parentId: number
    source: number
    sourceId: string
    userId: string
    userName: string
}

export const CardList: FC<{ data: any }> = ({ data }) => {
    return (
        data.map((item: ICardData) => (
            <ChatCard cardData={item} key={item?.id} /> 
        ))
    )
}

const ChatCard: FC<{ cardData: ICardData }> = ({ cardData }) => {
    return (
        <div className="w-full pb-3 border-b mt-4 h-full">
            <div className="flex justify-between w-full items-center mt-2">
                <div className="flex gap-2">
                    <div className="h-[46px] w-[46px] rounded-full overflow-hidden">
                        <img
                            src={avatarIcon}
                            alt="avatar"
                            className="rounded-full h-full w-full"
                        />
                    </div>
                    <div className="flex flex-col justify-center">
                        <p className="text-[#023E7D] font-bold">{cardData?.userName}</p>
                        <div className="flex  gap-3">
                            <p className="text-[#979797] text-xs">
                                {moment(cardData?.createdOn).format("DD/MM/YYYY")}
                            </p>
                            <p className="text-[#979797] text-xs border-l pl-3">
                                {moment(cardData?.createdOn).format("h:mm")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <p className="font-Inter text-lg font-light mt-2">
                {cardData?.comment}
            </p>
        </div>
    )
}
