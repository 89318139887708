import { useState } from 'react'
import { useQuery } from 'react-query'
import { getOperationType } from '../utils/services/settings'

export default function useOperationTypes() {
    const [operationTypes, setOperationTypes] = useState([])

    const payload = {}

    const { refetch } = useQuery(['GetAllOperationTypes'], () => getOperationType(payload), {
        enabled: true,
        retry: 2,
        onSuccess: (response) => {
            setOperationTypes(response.data)
        },
    })

    const fetchAllOperationTypes = () => {
        refetch();
    }

    return { operationTypes, fetchAllOperationTypes }
}
