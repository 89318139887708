import { devInstance } from '../axios/axiosInstance'

export const getEquipmentByOrganizationId = async (organizationId: number) => {
    const data = await devInstance.get(`/Equipment/getByOrganizationId?organizationId=${organizationId}`)
    return data
}

export const getAllEquipment = async () => {
    const data = await devInstance.get(`/Equipment/GetALL`)
    return data
}
