import { AxiosRequestConfig } from 'axios'
import { getSavedState } from '../localStorage'

export const requestInteceptor = (config: AxiosRequestConfig) => {
    const savedState = getSavedState('GHOSTUser')
    const ccpToken = savedState?.access_token

    config.headers = {
        ...config.headers,
        Authorization: `Bearer ${ccpToken}`,
    }
    return config
}
