import { devInstance } from '../axios/axiosInstance'

export const searchInvoice = async (payload: any) => {
    const data = await devInstance.post('/Invoice/search', payload)
    return data
}

export const createInvoice = async (payload: any) => {
    const data = await devInstance.post('/Invoice/Create', payload)
    return data
}
export const fetchInvoiceStatus = async () => {
    const data = await devInstance.get('/Setting/InvoiceStatusTypes')
    return data
}

export const    fetchCustomers = async () => {
    const data = await devInstance.get('/Contract/GetPartners')
    return data
}

export const approveInvoice = async (payload: any) => {
    const data = await devInstance.post(
        '/Invoice/Approve', payload)
    return data
}

export const declineInvoice = async (payload: any) => {
    const data = await devInstance.post(
        '/Invoice/Decline', payload)
    return data
}

export const discardInvoice = async (payload: any) => {
    const data = await devInstance.post(
        `/Invoice/Discard?invoiceId=${payload.invoiceId}`)
    return data
}