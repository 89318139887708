import { Suspense, lazy } from 'react'

import { Route, Routes, useNavigate } from 'react-router-dom'
const AuditTrail = lazy(() => import('../../../pages/AuditTrail/AuditTrail'))
const Invoices = lazy(() => import('../../../pages/Invoices/Invoice'))
const Dashboard = lazy(() => import('../../../pages/Dashboard/Dashboard'))
const Disputes = lazy(() => import('../../../pages/Disputes/Disputes'))
const HistoryDetailsPreview = lazy(
    () => import('../../../pages/TransactionHistory/HistoryDetailsPreview')
)
const MakerChecker = lazy(
    () => import('../../../pages/MakerChecker/MakerChecker')
)
const PageNotFound = lazy(() => import('../../../pages/NotFound/PageNotFound'))
import { Access } from '../../AuthRoute/AuthRoute'
import { dataReconcilliationRoutes } from './DataReconcilliation'
import { dataCaptureRoutes } from './DataCapture'
import { settingsRoutes } from './Settings'
const TransactionHistory = lazy(
    () => import('../../../pages/TransactionHistory/History')
)
const Report = lazy(() => import('../../../pages/Reports/Report'))
// const DataEntry = lazy(() => import('../../../pages/DataCapture/DataEntry/DataEntry'))
import { DataCaptureProvider } from '../../../pages/DataCapture/context/dcContext'
import { ScheduledProvider } from '../../../pages/DataCapture/GroundHandling/Scheduled/context/scheduledContext'
import { UnscheduledProvider } from '../../../pages/DataCapture/GroundHandling/Unscheduled/context/unScheduledContext'
import { ReconciliationProvider } from '../../../pages/DataReconcilliation/context'
import CreateExistingInvoice from '../../../pages/Invoices/CreateInvoice'
import useNetwork from '../../../hooks/useNetwork'
import CreateNewInvoice from '../../../pages/Invoices/NewInvoice'
import { getSavedState, getUserPermissions } from '../../../utils/localStorage'

import ApproveViaMail from '../../../pages/TransactionEmail/Email'
import Billing from '../../../pages/Billing/Billing'

export type IRoute = {
    path: string
    comp: JSX.Element
    isAllowed: boolean
}

const PermissionRoutes = () => {
    const navigate = useNavigate()
    const isOnline = useNetwork()
    const permissions = getUserPermissions()
    const GHOSTUser = getSavedState('GHOSTUser')
    const OFFLINE_ORG = getSavedState('OFFLINE_ORG')
    const { organizationTypeId: offlineOrgTypeId, userRole: offlineUserRole } =
        OFFLINE_ORG
    const orgTypeId = isOnline
        ? GHOSTUser?.organizationTypeId
        : offlineOrgTypeId
    const currentUserRole = isOnline ? GHOSTUser?.userRole : offlineUserRole

    return (
        <ReconciliationProvider>
            <UnscheduledProvider>
                <ScheduledProvider>
                    <DataCaptureProvider>
                        <Routes>
                            {[
                                ...dataReconcilliationRoutes,
                                // ...contractServiceRoutes,
                                // ...reportRoutes,
                                // ...settlementRoutes,
                                // ...dataCaptureRoutes,
                                ...settingsRoutes,
                                // ...workflowRoutes,
                            ].map((cs) => (
                                <Route
                                    key={cs.path}
                                    path={cs.path}
                                    element={
                                        <Access
                                            comp={cs.comp}
                                            isAllowed={cs.isAllowed}
                                        />
                                    }
                                />
                            ))}

                            {[...dataCaptureRoutes].map((cs) => (
                                <Route
                                    key={cs.path}
                                    path={cs.path}
                                    element={
                                        <Access
                                            comp={cs.comp}
                                            isAllowed={
                                                cs.isAllowed || !isOnline
                                            }
                                        />
                                    }
                                />
                            ))}

                            <Route
                                path="billing"
                                element={
                                    <Access
                                        comp={<Billing />}
                                        isAllowed={
                                            permissions.has('billing') &&
                                            (currentUserRole.toLowerCase() ===
                                                'billingofficer' ||
                                                currentUserRole.toLowerCase() ===
                                                    'internalcontrol')
                                        }
                                    />
                                }
                            />
                            <Route
                                path="transactions"
                                element={
                                    <Access
                                        comp={<TransactionHistory />}
                                        isAllowed={
                                            permissions.has('transactions'
                                            ) &&
                                            (currentUserRole.toLowerCase() ===
                                                'stationmanager' ||
                                                currentUserRole.toLowerCase() ===
                                                    'hq' ||
                                                (currentUserRole.toLowerCase() ===
                                                    'fieldagent'))
                                        }
                                    />
                                }
                            />

                            <Route
                                path="transactions/details/:id"
                                element={
                                    <Access
                                        comp={<HistoryDetailsPreview />}
                                        isAllowed={
                                           permissions.has('billing') || (permissions.has('transactions') &&
                                            (currentUserRole.toLowerCase() ===
                                                'stationmanager' ||
                                                currentUserRole.toLowerCase() ===
                                                    'billingofficer' ||
                                                currentUserRole.toLowerCase() ===
                                                    'internalcontrol' ||
                                                currentUserRole.toLowerCase() ===
                                                    'hq' ||
                                                (currentUserRole.toLowerCase() ===
                                                    'fieldagent')))
                                        }
                                    />
                                }
                            />

                            <Route
                                path="transactionhistory/:id"
                                element={
                                    <Access
                                        comp={<ApproveViaMail />}
                                        isAllowed={permissions.has(
                                            'transaction_email'
                                        )}
                                    />
                                }
                            />

                            {/* <Route
                                path="disputes"
                                element={
                                    <Access comp={<Disputes />} isAllowed={true} />
                                }
                            />
                            <Route
                                path="tasks"
                                element={
                                    <Access comp={<MyTasks />} isAllowed={true} />
                                }
                            /> */}
                            <Route
                                path="audit"
                                element={
                                    <Access
                                        comp={<AuditTrail />}
                                        isAllowed={true}
                                    />
                                }
                            />
                            <Route
                                path="reports"
                                element={
                                    <Access
                                        comp={<Report />}
                                        isAllowed={true}
                                    />
                                }
                            />
                            <Route
                                path="invoice"
                                element={
                                    <Access
                                        comp={<Invoices />}
                                        isAllowed={true}
                                    />
                                }
                            />
                            <Route
                                path="invoice/create-new-invoice"
                                element={
                                    <Access
                                        comp={<CreateNewInvoice />}
                                        isAllowed={true}
                                    />
                                }
                            />
                            <Route
                                path="invoice/create-invoice"
                                element={
                                    <Access
                                        comp={<CreateExistingInvoice />}
                                        isAllowed={true}
                                    />
                                }
                            />

                            {/* <Route
                                path="resources"
                                element={
                                    <Access comp={<Resources />} isAllowed={true} />
                                }
                            /> */}
                            <Route
                                path="dashboard"
                                element={
                                    <Access
                                        comp={<Dashboard />}
                                        isAllowed={true}
                                    />
                                }
                            />
                            {/* <Route
                                path="workflow"
                                element={
                                    <Access comp={<WorkFlow />} isAllowed={true} />
                                }
                            /> */}
                            <Route
                                path="maker-checker"
                                element={
                                    <Access
                                        comp={<MakerChecker />}
                                        isAllowed={true}
                                    />
                                }
                            />
                            <Route path="*" element={<PageNotFound />} />
                        </Routes>
                    </DataCaptureProvider>
                </ScheduledProvider>
            </UnscheduledProvider>
        </ReconciliationProvider>
    )
}

export default PermissionRoutes
