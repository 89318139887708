import React, { ReactElement, useState, useEffect } from 'react'
import GenericLayout from '../../components/GenericLayout'
import { CustomSelect } from '../../components/UIKit/CustomSelect/CustomSelect'
import { DatePicker } from '../../components/UIKit/DatePicker/Date'
// import { HistoryCardList } from '../../components/Billing/Table/CardList'
import {
    statusOptions,
    locationOptions,
    serviceTypeOptions,
    handlerOptions,
    downloadOptions,
} from './data'
import { PaginationComponent } from '../../components/UIKit/Pagination/PaginationComponent'
import { searchAudit, downloadAudit } from '../../utils/services/auditTrail'
import { useQuery, useMutation } from 'react-query'
import Skeleton from 'react-loading-skeleton'
import { ErrorCard } from '../../components/UIKit/errorCard'
import moment from 'moment'
import { PageCount } from '../../components/UIKit/PageCount/PageCount'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { EmptyState } from '../../components/UIKit/EmptyState/EmptyState'
import { downloadFile } from '../../utils/downloadFile'
import { Link } from 'react-router-dom'
import Select from 'react-select'

import cardIcon from '../../assets/img/Billing/card-icon.svg'
// import { OverviewCard } from '../../components/Billing/OverviewCard'
import { getSavedState, getUserPermissions } from '../../utils/localStorage'
import { transactionHistory } from '../../utils/services/transaction'
import { SearchInput } from '../../components/UIKit/Search/Search'
import usePartners from '../../hooks/usePartners'
import useLocationByOrgId from '../../hooks/useLocationByOrgId'
import { customStyles } from '../../utils/selectStyles'
// import { CSVLink } from "react-csv";
import { ButtonPrimary } from '../../components/UIKit/ButtonPrimary/Button'
import { CSVLink } from 'react-csv'
import useServiceCategory from '../../hooks/useServiceCategory'
import { getOfflineDataCapture } from '../../utils/localbase/offlineDataCapture'
import { DataCapture } from '../../utils/localbase/localbase.types'
import { OverviewCard } from '../../components/Billings/OverviewCard'
import { HistoryCardList } from '../../components/Billings/Table/CardList'
// import { OverviewCard } from '../../components/TransactionHistory copy/OverviewCard'

const permissions = getUserPermissions()

export default function Billing(): ReactElement {
    const localStorage: any = getSavedState('GHOSTUser')
    const { userRole, organizationTypeId, organizationId } = localStorage
    const [status, setStatus] = useState('Status')
    const [csvdata, setCsvData] = useState([])
    const [location, setLocation] = useState('Location')
    const [handler, setHandler] = useState(
        organizationTypeId === 1 ? 'Handler' : 'Airline'
    )
    const [downloadReport, setDownloadReport] = useState('Download Report')
    const { serviceCategory, getServiceCategory } = useServiceCategory()
    const [serviceType, setServiceType] = useState('Service Type')
    const [openTab, setOpenTab] = React.useState(
        userRole.toLowerCase() === 'billingofficer' ? 3 : 8
    )
    const [searchPhrase, setSearchPhrase] = useState('')

    const { customers, getCustomers } = usePartners()
    const { locations, getLocByOrgId } = useLocationByOrgId(organizationId)

    const [filters, setFilters] = useState({
        page: 1,
        pageSize: 10,
        parameter: {
            locationId: 0,
            partnerOrganization: 0,
            scenarioCaseName: '',
            staffName: '',
            startDate: moment().subtract(30, 'days').format('yy-MM-DD'),
            endDate: moment().format('yy-MM-DD'),
            status: openTab,
            // userId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            // producerId: 0,
            // consumerId: 0,
            // loggedInOrganizationId: 0,
            // dataEntryStatus: [0]
        },
    })
    const [offlineData, setOfflineData] = useState<DataCapture[]>([])

    const headers = [
        { label: 'Client Name', key: 'clientName' },
        { label: 'Organization Name', key: 'producerName' },
        { label: 'ApprovedBy', key: 'approvedByName' },
        { label: 'processedBy', key: 'processedByName' },
        { label: 'Aircraft', key: 'aircraft' },
        { label: 'Airline Code', key: 'airlineCode' },
        { label: 'Arrival Flight Number', key: 'arrivalFlightNumber' },
        { label: 'Departure Flight Number', key: 'departureFlightNumber' },
        { label: 'arrivalLocation', key: 'arrivalLocation' },
        { label: 'Departure Location', key: 'departureLocation' },
        { label: 'OperationTypeId', key: 'operationTypeId' },
        { label: 'Amount', key: 'grandTotal' },
        { label: 'Operation Type', key: 'operationType' },
    ]
    const {
        isLoading,
        isError,
        data: response,
        error,
        refetch,
    } = useQuery(
        [
            filters.page,
            filters.pageSize,
            filters.parameter.startDate,
            filters.parameter.endDate,
            filters.parameter.status,
            filters.parameter.staffName,
            filters.parameter.scenarioCaseName,
            filters.parameter.locationId,
            filters.parameter.partnerOrganization,
        ],
        () => transactionHistory(filters),
        {
            enabled: true,
            retry: false,
            onSuccess: (res) => {
                // console.log('res', res)
            },
            onError: (err) => {
                // console.log('err', err)
            },
        }
    )
    const csvReport = {
        data: csvdata,
        headers: headers,
        filename: 'transaction_history.csv',
    }

    const {
        mutateAsync,
        isLoading: isDownloading,
        isError: isDownloadErr,
        error: downloadError,
        data,
    } = useMutation(downloadAudit, {
        onSuccess: ({ data }) => {
            downloadFile(data?.responseType?.downloadUrl, 'audit_trail')
            toast.success(`${data?.responseType?.message}`)
        },
        onError: (error: AxiosError) => {
            toast.error(error?.response?.data?.errors[0])
        },
    })

    const handlePageChange = (page: { selected: number }) => {
        setFilters({
            ...filters,
            page: page?.selected + 1,
        })
    }

    const handleStatus = (input: string) => {
        setStatus(input)
        setFilters({
            ...filters,
            parameter: {
                ...filters.parameter,
                status: parseInt(input),
            },
        })
    }

    const handleLocation = (input: string) => {
        setLocation(input)
        // toast.success('downloading...')
        mutateAsync({
            ...filters,
            parameter: {
                ...filters.parameter,
                downloadType: input,
            },
        })
    }

    const handleServiceType = (input: string) => {
        setServiceType(input)
        // toast.success('downloading...')
        setFilters({
            ...filters,
            parameter: {
                ...filters.parameter,
                scenarioCaseName: input,
            },
        })
    }

    const handleHandler = (input: string) => {
        setHandler(input)
        // toast.success('downloading...')
        mutateAsync({
            ...filters,
            parameter: {
                ...filters.parameter,
                downloadType: input,
            },
        })
    }

    const handleStartDate = (input: string) => {
        setFilters({
            ...filters,
            parameter: {
                ...filters.parameter,
                startDate: input,
            },
        })
    }
    const handleEndDate = (input: string) => {
        setFilters({
            ...filters,
            parameter: {
                ...filters.parameter,
                endDate: input,
            },
        })
    }

    const handleTab = (e: any, index: number) => {
        e.preventDefault()
        setOpenTab(index)
        if (index === 9) {
            return
        }
        setFilters({
            ...filters,
            page: 1,
            parameter: {
                ...filters.parameter,
                status: index,
            },
        })
    }
     

    const handleSearch = (input: string) => {
        setSearchPhrase(input)
    }
    const searchAction = () => {
        setFilters({
            ...filters,
            parameter: {
                ...filters.parameter,
                staffName: searchPhrase,
            },
        })
    }
    const getcsvData = async () => {
        const res = await transactionHistory({ ...filters, pageSize: 10000000 })
        setCsvData(res?.data?.data)
    }

    useEffect(() => {
        refetch()
        getServiceCategory()
    }, [])
    useEffect(() => {
        getcsvData()
    }, [filters])

    useEffect(() => {
        fetchOfflineData()
    }, [])

    const fetchOfflineData = async () => {
        getLocByOrgId()
        await getCustomers()
        const data = await getOfflineDataCapture()
        if (data) {
            setOfflineData(data)
        }
    }
    console.log('Res', response)

    return (
        <GenericLayout breadcrumbs={[<Link to="/audit"> Billing </Link>]}>
            <div className="p-6 bg-[#F1F2F3]">
                <p className="font-bold text-2xl text-[#002555]">Billing</p>
                <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 xl:grid-cols-4">
                    {(userRole.toLowerCase() === 'billingofficer' ||
                        userRole.toLowerCase() === 'internalcontrol' ||
                        userRole.toLowerCase() === 'hq') && (
                        <OverviewCard
                            type="Accepted"
                            amount={response?.data?.accepted}
                        />
                    )}
                    {(userRole.toLowerCase() === 'billingofficer' ||
                        userRole.toLowerCase() === 'internalcontrol' ||
                        userRole.toLowerCase() === 'hq') && (
                        <OverviewCard
                            type="Awaiting Approval"
                            amount={response?.data?.billingUpdate}
                        />
                    )}
                    {(userRole.toLowerCase() === 'billingofficer' ||
                        userRole.toLowerCase() === 'internalcontrol' ||
                        userRole.toLowerCase() === 'hq') && (
                        <OverviewCard
                            type="Disputed"
                            amount={response?.data?.billingDisputed}
                        />
                    )}

                    {(userRole.toLowerCase() === 'billingofficer' ||
                        userRole.toLowerCase() === 'internalcontrol' ||
                        userRole.toLowerCase() === 'hq') && (
                        <OverviewCard
                            type={'Bin'}
                            amount={response?.data?.bin}
                        />
                    )}
                    {/* {(userRole.toLowerCase() === 'hq' ||
                        userRole.toLowerCase() === 'stationmanager') && (
                        <OverviewCard
                            type="Accepted"
                            amount={response?.data?.accepted}
                        />
                    )} */}
                    {/* {userRole.toLowerCase() !== 'fieldagent' && (
                        <OverviewCard
                            type="Disputed"
                            amount={response?.data?.disputed}
                        />
                    )} */}
                </div>
                <div className="w-full flex justify-between my-6">
                    <ul
                        className="flex flex-row flex-wrap mb-0 list-none border-b pb-0 md:w-3/4"
                        role="tablist"
                    >
                        {userRole.toLowerCase() === 'hq' ||
                            (userRole.toLowerCase() === 'stationmanager' &&
                                organizationTypeId === 2 && (
                                    <li className="-mb-px mr-2 last:mr-0  text-center">
                                        <a
                                            className={
                                                'text-xs font-bold capitalize pr-8 py-3 block leading-normal ' +
                                                (openTab === 1
                                                    ? ' text-black border-b-4 border-b-[#023E7D]'
                                                    : 'text-gray-600 ')
                                            }
                                            onClick={(e) => handleTab(e, 1)}
                                            data-toggle="tab"
                                            href="#link1"
                                            role="tablist"
                                        >
                                            Captured
                                        </a>
                                    </li>
                                ))}
                        {/* <li className="-mb-px mr-2 last:mr-0  text-center">
                            <a
                                className={
                                    'text-xs font-bold capitalize pr-5 py-3 block leading-normal ' +
                                    (openTab === 2
                                        ? ' text-black border-b-4 border-b-[#023E7D]'
                                        : 'text-gray-600 ')
                                }
                                onClick={(e) => handleTab(e, 2)}
                                data-toggle="tab"
                                href="#link2"
                                role="tablist"
                            >
                                {organizationTypeId === 1
                                    ? 'Received'
                                    : 'Approved'}
                            </a>
                        </li> */}
                        {userRole.toLowerCase() === 'billingofficer' && (
                            <li className="-mb-px mr-2 last:mr-0  text-center">
                                <a
                                    className={
                                        'text-xs font-bold capitalize pr-5 py-3 block leading-normal ' +
                                        (openTab === 3
                                            ? ' text-black border-b-4 border-b-[#023E7D]'
                                            : 'text-gray-600 ')
                                    }
                                    onClick={(e) => handleTab(e, 3)}
                                    data-toggle="tab"
                                    href="#link3"
                                    role="tablist"
                                >
                                    Accepted
                                </a>
                            </li>
                        )}
                        {(userRole.toLowerCase() === 'billingofficer' ||
                            userRole.toLowerCase() === 'internalcontrol') && (
                            <li className="-mb-px mr-2 last:mr-0  text-center">
                                <a
                                    className={
                                        'text-xs font-bold capitalize pr-5 py-3 block leading-normal ' +
                                        (openTab === 8
                                            ? ' text-black border-b-4 border-b-[#023E7D]'
                                            : 'text-gray-600 ')
                                    }
                                    onClick={(e) => handleTab(e, 8)}
                                    data-toggle="tab"
                                    href="#link4"
                                    role="tablist"
                                >
                                    Awaiting Approval
                                </a>
                            </li>
                        )}
                        {(userRole.toLowerCase() === 'billingofficer' ||
                            userRole.toLowerCase() === 'internalcontrol') && (
                            <li className="-mb-px mr-2 last:mr-0  text-center">
                                <a
                                    className={
                                        'text-xs font-bold capitalize px-5 py-3 block leading-normal ' +
                                        (openTab === 44
                                            ? ' text-black border-b-4 border-b-[#023E7D]'
                                            : 'text-gray-600 ')
                                    }
                                    onClick={(e) => handleTab(e, 44)}
                                    data-toggle="tab"
                                    href="#link5"
                                    role="tablist"
                                >
                                    Disputed
                                </a>
                            </li>
                        )}
                        {/* {(userRole.toLowerCase() === 'hq' ||
                            (userRole.toLowerCase() === 'stationmanager' &&
                                organizationTypeId === 2) ||
                            userRole.toLowerCase() === 'fieldagent') && (
                            <li className="-mb-px mr-2 last:mr-0  text-center">
                                <a
                                    className={
                                        'text-xs font-bold capitalize px-5 py-3 block leading-normal ' +
                                        (openTab === 7
                                            ? ' text-black border-b-4 border-b-[#023E7D]'
                                            : 'text-gray-600 ')
                                    }
                                    onClick={(e) => handleTab(e, 7)}
                                    data-toggle="tab"
                                    href="#link6"
                                    role="tablist"
                                >
                                    Pending
                                </a>
                            </li>
                        )} */}
                        <li className="-mb-px mr-2 last:mr-0  text-center">
                            <a
                                className={
                                    'text-xs font-bold capitalize px-5 py-3 block leading-normal ' +
                                    (openTab === 7
                                        ? ' text-black border-b-4 border-b-[#023E7D]'
                                        : 'text-gray-600 ')
                                }
                                onClick={(e) => handleTab(e, 7)}
                                data-toggle="tab"
                                href="#link7"
                                role="tablist"
                            >
                                Bin
                            </a>
                        </li>
                        {userRole.toLowerCase() === 'fieldagent' &&
                            organizationTypeId === 2 && (
                                <li className="-mb-px mr-2 last:mr-0  text-center">
                                    <a
                                        className={
                                            'text-xs font-bold capitalize px-5 py-3 block leading-normal ' +
                                            (openTab === 9
                                                ? ' text-black border-b-4 border-b-[#023E7D]'
                                                : 'text-gray-600 ')
                                        }
                                        onClick={(e) => handleTab(e, 9)}
                                        data-toggle="tab"
                                        href="#link8"
                                        role="tablist"
                                    >
                                        Draft
                                    </a>
                                </li>
                            )}
                    </ul>
                    <div>
                        {/* <PageCount
                            options={downloadOptions}
                            handleEntriesCount={handleDownloadReport}
                            entriesCount={downloadReport}
                            type={true}
                        /> */}
                    </div>
                    <div className="f ">
                        {csvdata?.length > 0 && (
                            <CSVLink {...csvReport}>
                                {' '}
                                <div className="flex justify-center text-center  p-2 border-[#023E7D] bg-[#023E7D]  text-white rounded">
                                    Export to csv
                                </div>
                            </CSVLink>
                        )}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row md:gap-6 gap-3 mt-8">
                    {/* {(userRole.toLowerCase() === 'stationmanager' || userRole.toLowerCase() === 'fieldagent') && (
                        <div>
                            <PageCount
                                options={statusOptions}
                                handleEntriesCount={handleStatus}
                                entriesCount={status}
                                type={true}
                            />
                        </div>
                    )} */}
                    <div>
                        <DatePicker
                            startDate={filters.parameter.startDate}
                            endDate={filters.parameter.endDate}
                            handleStartDate={handleStartDate}
                            handleEndDate={handleEndDate}
                        />
                    </div>
                    <div className="w-full md:w-[calc(12%)]">
                        <Select
                            placeholder={
                                organizationTypeId === 1 ? 'Handler' : 'Airline'
                            }
                            options={customers?.map((item: any) => {
                                return {
                                    value: item.organizationId,
                                    label: item?.organizationName,
                                }
                            })}
                            onChange={(newValue: any) =>
                                setFilters({
                                    ...filters,
                                    parameter: {
                                        ...filters.parameter,
                                        partnerOrganization: newValue.value,
                                    },
                                })
                            }
                            // defaultValue={values.industry}
                            styles={customStyles}
                        />
                    </div>
                    <div className="w-full md:w-[calc(12%)]">
                        <Select
                            placeholder="Location"
                            options={locations?.map((item: any) => {
                                return {
                                    value: item.locationId,
                                    label: item.locationName,
                                }
                            })}
                            onChange={(newValue: any) =>
                                setFilters({
                                    ...filters,
                                    parameter: {
                                        ...filters.parameter,
                                        locationId: newValue.value,
                                    },
                                })
                            }
                            // defaultValue={values.industry}
                            styles={customStyles}
                        />
                    </div>
                    <div className="w-full md:w-[calc(12%)]">
                        <Select
                            placeholder="Service Type"
                            options={serviceCategory?.map((item: any) => {
                                return {
                                    value: item.name,
                                    label: item.name,
                                }
                            })}
                            onChange={(newValue: any) =>
                                setFilters({
                                    ...filters,
                                    parameter: {
                                        ...filters.parameter,
                                        scenarioCaseName: newValue.value,
                                    },
                                })
                            }
                            // defaultValue={values.industry}
                            styles={customStyles}
                        />
                    </div>

                    {userRole.toLowerCase() !== 'fieldagent' && (
                        <div className="">
                            <SearchInput
                                value={searchPhrase}
                                handleSearch={handleSearch}
                                onAction={searchAction}
                            />
                        </div>
                    )}
                    {/* <div>
                        <PageCount
                            options={serviceTypeOptions}
                            handleEntriesCount={handleDownloadReport}
                            entriesCount={downloadReport}
                            type={true}
                        />
                    </div> */}
                </div>

                {openTab !== 9 && (
                    <>
                        {isLoading && (
                            <div className="pt-8">
                                <Skeleton
                                    className="px-5 py-3 my-2 h-32"
                                    count={5}
                                />
                            </div>
                        )}
                        {isError && <ErrorCard />}
                        {response?.data?.data?.length === 0 && (
                            <div className="mt-8">
                                <EmptyState />
                            </div>
                        )}
                        {response?.data?.data?.length > 0 && (
                            <div className="mt-4">
                                <HistoryCardList
                                    cardList={response?.data?.data}
                                    refetch={refetch}
                                    openTab={openTab}
                                />
                                <PaginationComponent
                                    pageSize={filters.pageSize}
                                    totalCounts={response?.data.totalCount}
                                    handlePageChange={handlePageChange}
                                    currentPage={filters.page}
                                />
                            </div>
                        )}
                    </>
                )}
                {openTab === 9 && (
                    <>
                        {offlineData.length === 0 && (
                            <div className="mt-8">
                                <EmptyState />
                            </div>
                        )}
                        {offlineData.length > 0 && (
                            <div className="mt-4">
                                <HistoryCardList
                                    cardList={offlineData}
                                    openTab={openTab}
                                    fetchOfflineData={fetchOfflineData}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </GenericLayout>
    )
}
