import { getSavedState } from '../localStorage'
import { getAllEquipment, getEquipmentByOrganizationId } from '../services/equipment'
import { db } from './localbase'
import { Equipment } from './localbase.types'

const OFFLINE_ORG = getSavedState('OFFLINE_ORG')

export async function setupEquipments() {
    //fetch and persist equipments
    const equipmentResp = await getAllEquipment()
    if (equipmentResp.data) {
        await addEquipment(equipmentResp.data)
    }
}

export async function addEquipment(data: any) {
    try {
        await db.equipments.bulkAdd(data)
    } catch (ex) {}
}

export async function getEquipmentsByOrg() {
    let equipments:Equipment[] = []
    try {
         equipments = await db.equipments.toArray()
    } catch (error) {

    }
    return equipments
}
