import React, { FC } from 'react'
import { timeDifference } from '../../../utils/timeDifference'
import Skeleton from 'react-loading-skeleton'
import { AppModal } from '../../UserSettings/Modal'
import { ButtonOutlined } from '../../UIKit/ButtonOutlined/Button'
import { ButtonPrimary } from '../../UIKit/ButtonPrimary/Button'
import image1 from '../../../assets/img/TransactionHistory/image1.svg'
import image2 from '../../../assets/img/TransactionHistory/image2.svg'
import image3 from '../../../assets/img/TransactionHistory/image3.svg'
import { Avatar } from '../../TransactionHistory/Avatar'
import editIcon from '../../../assets/img/TransactionHistory/edit.svg'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { getSavedState } from '../../../utils/localStorage'

type DetailsModalT = {
    showDetailsModal: any
    closeApproval: any
    entryDetail: any
    isLoading: any
    grandTotal: any
    setShowDetailsModal: any
    setShowReason: any
    setShowDisputeTypeModal: any
    setShowApproveModal: any
}

const DetailsModal: FC<DetailsModalT> = ({
    showDetailsModal,
    closeApproval,
    entryDetail,
    isLoading,
    grandTotal,
    setShowDetailsModal,
    setShowReason,
    setShowDisputeTypeModal,
    setShowApproveModal,
}) => {
    const navigate = useNavigate()
    const userToken = getSavedState('GHOSTUser') || {}
    const { userRole, organizationTypeId } = userToken
    const isStationManager = userRole.toLowerCase() === 'stationmanager'

    const isAirlineManagerAporoval =
        isStationManager &&
        entryDetail?.status === 1 &&
        organizationTypeId === 1

    const isVendorManagerApproval =
        isStationManager &&
        (entryDetail?.status === 5 || entryDetail?.status === 0) &&
        organizationTypeId === 2

    return (
        <AppModal
            title=""
            isOpen={showDetailsModal}
            setIsOpen={closeApproval}
            containerClass="w-full sm:w-full lg:w-[860px]"
            contentClass="h-auto rounded-lg"
            showCloseButton={false}
            childrenContainer="w-full rounded-lg flex flex-col p-6 pb-8"
        >
            <div className="flex flex-col">
                <div className="flex justify-between items-center">
                    <p className="mt-4 font-Inter font-bold mb-4">
                        Ground Handling Services
                    </p>
                    <div
                        className="cursor-pointer text-[red] border-2 border-[red] rounded-full w-6 h-6 flex justify-center items-center font-extrabold"
                        onClick={() => navigate('/transactions')}
                    >
                        X
                    </div>
                </div>
                <div className="w-full h-20 bg-[#FBFBFB] flex justify-center">
                    <div className="w-[500px] flex h-full items-center">
                        <Avatar
                            label="Captured by"
                            image={image1}
                            name={entryDetail?.capturedByName}
                        />
                        <Avatar
                            label="Approved by"
                            image={image2}
                            name={entryDetail?.approvedByName}
                        />
                        {userRole.toLowerCase() !== 'fieldagent' && (
                            <Avatar
                                label={
                                    entryDetail?.status === 4
                                        ? 'Disputed by'
                                        : 'Accepted by'
                                }
                                image={image3}
                                name={entryDetail?.processedByName}
                            />
                        )}
                    </div>
                </div>
                <div className="w-full h-20 bg-[#EFEFEF] flex justify-center">
                    <div className="w-[600px] flex items-center">
                        <div className="border-r text-xs border-r-gray-400 px-5 min-w-fit w-[25%]">
                            <div className="text-[#4F4F4F] text-xs mb-2">
                                Date:
                            </div>
                            <div className="text-[#002555] capitalize font-semibold text-xs ">
                                {isLoading ? (
                                    <Skeleton />
                                ) : (
                                    moment(entryDetail?.operationDate).format(
                                        'DD/MM/YYYY'
                                    )
                                )}
                            </div>
                        </div>
                        <div className="border-r text-xs border-r-gray-400 px-5 min-w-fit w-[25%]">
                            <div className="text-[#4F4F4F] text-xs mb-2">
                                Operation Type:
                            </div>
                            <div className="text-[#002555] capitalize font-semibold text-xs ">
                                {isLoading ? (
                                    <Skeleton />
                                ) : (
                                    entryDetail?.OperationType ||
                                    entryDetail?.handlingType
                                )}
                            </div>
                        </div>
                        <div className="border-r text-xs border-r-gray-400 px-5 min-w-fit w-[25%]">
                            <div className="text-[#4F4F4F] text-xs mb-2">
                                Aircraft Type:
                            </div>
                            <div className="text-[#002555] uppercase font-semibold text-xs ">
                                {isLoading ? (
                                    <Skeleton />
                                ) : (
                                    entryDetail?.aircraft
                                )}
                            </div>
                        </div>
                        <div className="text-xs px-5 min-w-fit w-[25%]">
                            <div className="text-[#4F4F4F] text-xs mb-2">
                                Airline code:
                            </div>
                            <div className="text-[#002555] uppercase font-semibold text-xs ">
                                {isLoading ? (
                                    <Skeleton />
                                ) : (
                                    entryDetail?.airlineCode
                                )}
                            </div>
                        </div>

                        <div className="border-r text-xs border-r-gray-400 px-5 min-w-fit w-[25%]">
                            <div className="text-[#4F4F4F] text-xs mb-2">
                                Flight No:
                            </div>
                            <div className="text-[#002555] uppercase font-semibold text-xs ">
                                {entryDetail?.departureFlightNumber} /{' '}
                                {entryDetail?.arrivalFlightNumber}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex justify-center mt-2">
                    <table className="w-full">
                        <thead className="bg-[#FBFBFB]">
                            <tr>
                                <th className="w-[8%]">&nbsp;</th>
                                <th className="pb-4 pt-4 text-left w-[15%] text-xs text-[#5B5C60]">
                                    Ref Number
                                </th>
                                <th className="pb-4 pt-4 text-left w-[25%] text-xs text-[#5B5C60]">
                                    Equipemnt
                                </th>
                                <th className="w-[3%]">&nbsp;</th>
                                <th className="pb-4 pt-4 text-left w-[25%] text-xs text-[#5B5C60]">
                                    QTY
                                </th>

                                <th className="pb-4 pt-4 text-left text-xs w-[35%] text-[#5B5C60]">
                                    Amount
                                </th>
                                {userRole.toLowerCase() === 'fieldagent' &&
                                    (organizationTypeId === 1 ||
                                        (organizationTypeId === 2 &&
                                            userRole.toLowerCase() ===
                                                'hq')) && (
                                        <th className="pb-4 pt-4 text-left text-xs w-[35%] text-[#5B5C60]">
                                            Amount
                                        </th>
                                    )}
                            </tr>
                        </thead>
                        <tbody>
                            {(
                                entryDetail?.metadata || entryDetail?.MetaData
                            )?.map((item: any) => (
                                <tr>
                                    <td className="pl-10">&nbsp;</td>
                                    <td className="text-[#5B5C60] font-Inter font-semibold">
                                        {isLoading ? (
                                            <Skeleton />
                                        ) : (
                                            entryDetail?.referenceNumber || ''
                                        )}
                                    </td>
                                    <td className="text-[#5B5C60] font-Inter font-semibold">
                                        {isLoading ? (
                                            <Skeleton />
                                        ) : (
                                            item?.serviceType ||
                                            item?.ServiceType
                                        )}
                                    </td>
                                    <td className="">
                                        {userRole.toLowerCase() ===
                                            'stationmanager' &&
                                            entryDetail?.status === 4 &&
                                            organizationTypeId === 2 && (
                                                <div
                                                    className="h-5 w-5 cursor-pointer"
                                                    onClick={() => {
                                                        // setEditEntryModal(item);
                                                        // setMetaDataIdx(item?.index)
                                                    }}
                                                >
                                                    <img
                                                        src={editIcon}
                                                        alt="edit"
                                                    />
                                                </div>
                                            )}
                                    </td>
                                    <td className="text-[#5B5C60] font-Inter font-semibold">
                                        {isLoading ? (
                                            <Skeleton />
                                        ) : item?.servicePeriodHourlyEnd ||
                                          item?.ServicePeriodHourlyEnd ? (
                                            timeDifference(
                                                item?.servicePeriodHourlyEnd ||
                                                    item?.ServicePeriodHourlyEnd,
                                                item?.servicePeriodHourlyStart ||
                                                    item?.ServicePeriodHourlyStart
                                            )
                                        ) : (
                                            item?.servicePeriod ||
                                            item?.ServicePeriod
                                        )}
                                    </td>

                                    <td className="text-[#000000] font-Inter font-semibold">
                                        {isLoading ? (
                                            <Skeleton />
                                        ) : (
                                            <span>
                                                &#8358;
                                                {Number(
                                                    item?.amount
                                                ).toLocaleString('en-US')}
                                            </span>
                                        )}
                                    </td>
                                    {userRole.toLowerCase() === 'fieldagent' &&
                                        (organizationTypeId === 1 ||
                                            (organizationTypeId === 2 &&
                                                userRole.toLowerCase() ===
                                                    'hq')) && (
                                            <td className="text-[#000000] font-Inter font-semibold">
                                                {isLoading ? (
                                                    <Skeleton />
                                                ) : (
                                                    <span>
                                                        &#8358;
                                                        {Number(
                                                            item?.amount
                                                        ).toLocaleString(
                                                            'en-US'
                                                        )}
                                                    </span>
                                                )}
                                            </td>
                                        )}
                                </tr>
                            ))}
                            <tr>
                                <td className="text-[#5B5C60] font-Inter font-semibold">
                                    &nbsp;
                                </td>
                                <td className="text-[#5B5C60] font-Inter font-semibold">
                                    &nbsp;
                                </td>
                                <td className="text-[#000000] font-Inter font-semibold">
                                    &nbsp;
                                </td>
                                <td className="text-[#000000] font-Inter font-semibold">
                                    &nbsp;
                                </td>
                            </tr>
                            {/* {((userRole.toLowerCase() !== 'fieldagent') && (entryDetailStatus !== 4
                        )) && ( */}
                            <tr className="bg-[#DDE7F1] border-t border-t-[#999090] border-b border-b-[#999090]">
                                <td className=""></td>
                                <td className=""></td>
                                <td className=""></td>
                                <td className=""></td>
                                <td className="text-[#023E7D] font-Inter font-semibold text-right md:pr-10">
                                    Grand Total
                                </td>
                                <td className="text-[#000000] font-Inter font-semibold">
                                    {isLoading ? (
                                        <Skeleton />
                                    ) : (
                                        <span>
                                            &#8358;
                                            {Number(grandTotal).toLocaleString(
                                                'en-US'
                                            )}
                                        </span>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {(isAirlineManagerAporoval || isVendorManagerApproval) && (
                    <div className="flex justify-center">
                        <div className="my-6 gap-6 flex">
                            {isLoading ? (
                                <Skeleton />
                            ) : (
                                <ButtonOutlined
                                    type="button"
                                    label={
                                        organizationTypeId === 2
                                            ? 'Decline'
                                            : 'Dispute'
                                    }
                                    handleClick={() => {
                                        setShowDetailsModal(false)
                                        setShowReason(false)
                                        setShowDisputeTypeModal(true)
                                    }}
                                />
                            )}

                            <ButtonPrimary
                                type="button"
                                label={
                                    organizationTypeId === 2
                                        ? 'Approve'
                                        : 'Accept'
                                }
                                handleClick={() => {
                                    setShowApproveModal(true)
                                    setShowDetailsModal(false)
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </AppModal>
    )
}

export default DetailsModal
