import React, { ReactElement } from 'react'

interface IDatePicker {
    startDate: string
    endDate: string
    handleStartDate: any
    handleEndDate: any
    variant?: 'small' | 'medium' | 'large'
}

export function DatePicker({
    startDate,
    endDate,
    handleStartDate,
    handleEndDate,
    variant,
}: IDatePicker): ReactElement {
    if (variant === 'small') {
        return (
            <div className="w-full relative flex gap-2">
                <input
                    type="date"
                    name=""
                    id=""
                    value={startDate}
                    onChange={(e) => handleStartDate(e.target.value)}
                    placeholder="Start Date"
                    className="rounded w-[93px] h-[30px] p-[5px] border border-[#D7DCE0] text-[#3A434B] text-[10px] placeholder-[#0D0F11] placeholder:text-[13px]"
                />
                <input
                    type="date"
                    name=""
                    id=""
                    value={endDate}
                    onChange={(e) => handleEndDate(e.target.value)}
                    className="rounded w-[93px] h-[30px] p-[5px] border border-[#D7DCE0] text-[#3A434B] text-[10px] placeholder-[#0D0F11] placeholder:text-[13px]"
                />
            </div>
        )
    }
    return (
        <div className="w-full relative flex gap-4">
            <input
                type="date"
                name=""
                id=""
                value={startDate}
                onChange={(e) => handleStartDate(e.target.value)}
                placeholder="Start Date"
                className="rounded h-11 border border-[#D7DCE0] text-[#3A434B] text-[13px] placeholder-[#0D0F11] placeholder:text-[13px]"
            />
            <input
                type="date"
                name=""
                id=""
                value={endDate}
                onChange={(e) => handleEndDate(e.target.value)}
                className="rounded border border-[#D7DCE0] text-[#3A434B] text-[13px] placeholder-[#0D0F11] placeholder:text-[13px]"
            />
        </div>
    )
}
