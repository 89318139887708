import { devInstance } from '../axios/axiosInstance'

export const fetchFlightSchedule = async (payload: any) => {
    const data = await devInstance.post('/FlightSchedule/Search', payload)
    return data
}

export const fetchFlightByOrgId = async (orgIds: any[]) => {
    let params = new URLSearchParams();

   orgIds.forEach(id => {
      params.append("OrganizationIds", id)
    })

    
    const data = await devInstance.get(`/Flight/get/organization?${params.toString()}`)
    return data
}
