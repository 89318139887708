import { useState } from 'react'
import { useQuery } from 'react-query'
import { getLocationByOrganizationId } from '../utils/services//settings/organization'
import { fetchCustomers } from '../utils/services/invoice'

export default function usePartners() {
    const [customers, setCustomers] = useState([])

    const { refetch } = useQuery(['GetCustomers'], () => fetchCustomers(), {
        enabled: true,
        retry: 2,
        staleTime:0,
        onSuccess: (response) => {
            setCustomers(response.data)
        },
    })

    const getCustomers = () => {
        refetch();
    }

    return { customers, getCustomers }
}
