import moment from 'moment'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getSavedState } from '../../../utils/localStorage'
import { timeDifference } from '../../../utils/timeDifference'
import { ButtonOutlined } from '../../UIKit/ButtonOutlined/Button'
import { ButtonPrimary } from '../../UIKit/ButtonPrimary/Button'
import { AppModal } from '../../UserSettings/Modal'
import editIcon from '../../../assets/img/TransactionHistory/edit.svg'
import personIcon from '../../../assets/img/TransactionHistory/personIcon.png'
import cancelIcon from '../../../assets/img/TransactionHistory/cancel.svg'

import { Avatar } from '../Avatar'
type EditEntryDetailsProps = {
    selectedntryDetail: any
    entryDetail: any
    setEntryDetail: any
    selectedntryDetailStatus: any
    openTab: any
    cardListCopy: any
    setcardListCopy: any
    entryIdx: any
    metaDataIdx: any
    setShowReason: any
    setMetaDataIdx: any
    setEditEntryModal: any
    setOpenEditAmountModal: any
    setLineItem: any
    setShowConfirmation: any
    setConfirmType: any
    setComment: any
}

const EntryDetails = (props: EditEntryDetailsProps) => {
    const userToken = getSavedState('GHOSTUser')
    const { userRole, organizationTypeId } = userToken
    const navigate = useNavigate()

    const {
        selectedntryDetail,
        entryDetail,
        setEntryDetail,
        selectedntryDetailStatus,
        openTab,
        cardListCopy,
        setcardListCopy,
        entryIdx,
        metaDataIdx,
        setComment,
        setShowReason,
        setMetaDataIdx,
        setEditEntryModal,
        setOpenEditAmountModal,
        setLineItem,
        setShowConfirmation,
        setConfirmType,
    } = props
    const [isEditMode, setIsEditMode] = useState(false)
    const [userActionType, setUserActionType] = useState(
        userRole.toLowerCase() === 'billingofficer' ? 'BILLED BY' : 'EDITED BY'
    )

    const grandTotal = selectedntryDetail?.metadata?.reduce(
        (acc: any, item: any) => {
            acc = acc + parseInt(item?.amount)
            return acc
        },
        0
    )
    console.log('opebTab1', openTab)

    return (
        <AppModal
            title=""
            isOpen={entryDetail !== null}
            setIsOpen={setEntryDetail}
            containerClass="w-full sm:w-full lg:w-[1020px]"
            contentClass="h-auto rounded-lg"
            showCloseButton={false}
            childrenContainer="w-full rounded-lg flex flex-col p-6 pb-8"
        >
            <div className="flex flex-col">
                <div className="flex items-center">
                    <div className="flex items-center w-full justify-between mr-6">
                        <p className="mt-4 font-Inter font-bold mb-4 text-[#5B5C60]">
                            Ground Handling Services Note for:{' '}
                            {selectedntryDetail?.clientName}
                        </p>
                        <p className="uppercase font-Inter font-bold text-[#023E7D]">
                            {openTab !== 9 && (
                                <>{selectedntryDetail?.referenceNumber || ''}</>
                            )}
                        </p>
                    </div>
                    <div
                        className="cursor-pointer text-[red] border-2 border-[red] rounded-full w-6 h-6 flex justify-center items-center font-extrabold"
                        onClick={() => setEntryDetail(false)}
                    >
                        X
                    </div>
                </div>
                <div className="w-full h-20 bg-[#FBFBFB] flex justify-center">
                    <div className="w-[500px] flex h-full items-center">
                        <Avatar
                            label={userActionType}
                            image={personIcon}
                            name={selectedntryDetail?.capturedByName}
                        />
                        {/* {openTab !== 9 && (
                    <Avatar
                        label="Approved by"
                        image={personIcon}
                        name={
                            selectedntryDetail?.approvedByName
                        }
                    />
                )} */}
                        {/* {userRole.toLowerCase() !== 'fieldagent' && (
                    <Avatar
                        label={
                            selectedntryDetail?.status === 4
                                ? 'Disputed by'
                                : 'Accepted by'
                        }
                        image={personIcon}
                        name={
                            selectedntryDetail?.processedByName
                        }
                    />
                )} */}
                    </div>
                </div>
                <div className="w-full h-20 bg-[#EFEFEF] flex justify-center">
                    <div className="w-full flex items-center">
                        <div className="border-r text-xs border-r-gray-400 px-5 min-w-fit w-[25%]">
                            <div className="text-[#4F4F4F] text-xs mb-2">
                                Date:
                            </div>
                            <div className="text-[#002555] capitalize font-semibold text-xs ">
                                {moment(
                                    selectedntryDetail?.operationDate
                                ).format('DD/MM/YYYY')}
                            </div>
                        </div>
                        <div className="border-r text-xs border-r-gray-400 px-5 min-w-fit w-[25%]">
                            <div className="text-[#4F4F4F] text-xs mb-2">
                                Operation Type:
                            </div>
                            <div className="text-[#002555] capitalize font-semibold text-xs ">
                                {selectedntryDetail?.OperationType ||
                                    selectedntryDetail?.handlingType}
                            </div>
                        </div>
                        <div className="border-r text-xs border-r-gray-400 px-5 min-w-fit w-[25%]">
                            <div className="text-[#4F4F4F] text-xs mb-2">
                                Aircraft Type:
                            </div>
                            <div className="text-[#002555] uppercase font-semibold text-xs ">
                                {selectedntryDetail?.metadata[0]?.aircraftType}
                            </div>
                        </div>
                        <div className="border-r text-xs border-r-gray-400 px-5 min-w-fit w-[25%]">
                            <div className="text-[#4F4F4F] text-xs mb-2">
                                Aircraft Series:
                            </div>
                            <div className="text-[#002555] uppercase font-semibold text-xs ">
                                {selectedntryDetail?.aircraft}
                            </div>
                        </div>
                        <div className="border-r text-xs border-r-gray-400 px-5 min-w-fit w-[25%]">
                            <div className="text-[#4F4F4F] text-xs mb-2">
                                Airline code:
                            </div>
                            <div className="text-[#002555] uppercase font-semibold text-xs ">
                                {selectedntryDetail?.airlineCode}
                            </div>
                        </div>
                        <div className="border-r text-xs border-r-gray-400 px-5 min-w-fit w-[25%]">
                            <div className="text-[#4F4F4F] text-xs mb-2">
                                Flight No:
                            </div>
                            <div className="text-[#002555] uppercase font-semibold text-xs ">
                                {selectedntryDetail?.departureFlightNumber} /{' '}
                                {selectedntryDetail?.arrivalFlightNumber}
                            </div>
                        </div>
                        <div className="text-xs px-5 min-w-fit w-[25%]">
                            <div className="text-[#4F4F4F] text-xs mb-2">
                                Location:
                            </div>
                            <div className="text-[#002555] uppercase font-semibold text-xs ">
                                {selectedntryDetail?.location}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex justify-center mt-2">
                    <table className="w-full">
                        <thead className="bg-[#FBFBFB]">
                            <tr>
                                <th className="w-[8%]">&nbsp;</th>
                                <th className="pb-4 pt-4 text-left w-[35%] text-xs text-[#5B5C60]">
                                    Equipemnt
                                </th>
                                <th className="w-[3%]">&nbsp;</th>
                                <th className="pb-4 pt-4 text-left w-[25%] text-xs text-[#5B5C60]">
                                    QTY
                                </th>

                                <th className="pb-4 pt-4 text-left text-xs w-[35%] text-[#5B5C60]">
                                    Amount
                                </th>

                                {userRole.toLowerCase() === 'internalcontrol' &&
                                    organizationTypeId === 2 && (
                                        <th className="pb-4 pt-4 text-left text-xs w-[35%] text-[#5B5C60]">
                                            New Amount
                                        </th>
                                    )}
                            </tr>
                        </thead>
                        <tbody>
                            {(
                                selectedntryDetail?.metadata ||
                                selectedntryDetail?.MetaData
                            )?.map((item: any) => (
                                <tr>
                                    <td className="pl-10">
                                        {userRole.toLowerCase() ===
                                            'stationmanager' &&
                                            selectedntryDetail?.status === 4 &&
                                            organizationTypeId === 2 && (
                                                <div
                                                    className="h-5 w-5 cursor-pointer"
                                                    onClick={() => {
                                                        const tempCopy = [
                                                            ...cardListCopy,
                                                        ]
                                                        // let updatedData = tempCopy[entryIdx].metadata.filter((item:any) => item?.index !== metaDataIdx);
                                                        let updatedData =
                                                            tempCopy.map(
                                                                (
                                                                    item: any,
                                                                    idx: any
                                                                ) => {
                                                                    if (
                                                                        idx ===
                                                                        entryIdx
                                                                    ) {
                                                                        return {
                                                                            ...item,
                                                                            metadata:
                                                                                item?.metadata?.filter(
                                                                                    (
                                                                                        item: any
                                                                                    ) =>
                                                                                        item?.index !==
                                                                                        metaDataIdx
                                                                                ),
                                                                        }
                                                                    } else
                                                                        return item
                                                                }
                                                            )

                                                        setcardListCopy(
                                                            updatedData
                                                        )
                                                    }}
                                                >
                                                    <img
                                                        src={cancelIcon}
                                                        alt="cancel"
                                                    />
                                                </div>
                                            )}
                                    </td>
                                    <td className="text-[#5B5C60] font-Inter font-semibold">
                                        {item?.serviceType || item?.ServiceType}
                                    </td>
                                    <td className="">
                                        {userRole.toLowerCase() ===
                                            'stationmanager' &&
                                            selectedntryDetail?.status === 4 &&
                                            organizationTypeId === 2 && (
                                                <div
                                                    className="h-5 w-5 cursor-pointer"
                                                    onClick={() => {
                                                        setComment('')
                                                        // setEditEntryModal(item)
                                                        setMetaDataIdx(
                                                            item?.index
                                                        )
                                                    }}
                                                >
                                                    <img
                                                        src={editIcon}
                                                        alt="edit"
                                                    />
                                                </div>
                                            )}
                                    </td>
                                    <td className="text-[#5B5C60] font-Inter font-semibold">
                                        {item?.servicePeriodHourlyEnd ||
                                        item?.ServicePeriodHourlyEnd
                                            ? timeDifference(
                                                  item?.servicePeriodHourlyEnd ||
                                                      item?.ServicePeriodHourlyEnd,
                                                  item?.servicePeriodHourlyStart ||
                                                      item?.ServicePeriodHourlyStart
                                              )
                                            : item?.servicePeriod ||
                                              item?.ServicePeriod}
                                    </td>

                                    <td className="text-[#000000] font-Inter font-semibold">
                                        &#8358;{' '}
                                        {Number(item?.amount).toLocaleString(
                                            'en-US'
                                        )}
                                        <span
                                            className="inline-block ml-10 cursor-pointer "
                                            onClick={() => {
                                                setComment('')
                                                setOpenEditAmountModal(true)
                                                setLineItem(item)
                                                setMetaDataIdx(item?.index)

                                            }}
                                        >
                                            {' '}
                                            {isEditMode &&
                                                userRole.toLowerCase() ===
                                                    'billingofficer' &&
                                                organizationTypeId === 2 && (
                                                    <img
                                                        src={editIcon}
                                                        alt=""
                                                    />
                                                )}
                                        </span>
                                    </td>
                                    {userRole.toLowerCase() ===
                                        'internalcontrol' &&
                                        organizationTypeId === 2 && (
                                            <td className="text-[#000000] font-Inter font-semibold ">
                                                <span className="bg-[#F3F9FF] px-6 py-2 whitespace-nowrap">
                                                    &#8358;{' '}
                                                    {Number(
                                                        item?.updatedAmount
                                                    ).toLocaleString('en-US')}
                                                </span>
                                            </td>
                                        )}
                                </tr>
                            ))}
                            <tr>
                                <td className="text-[#5B5C60] font-Inter font-semibold">
                                    &nbsp;
                                </td>
                                <td className="text-[#5B5C60] font-Inter font-semibold">
                                    &nbsp;
                                </td>
                                <td className="text-[#000000] font-Inter font-semibold">
                                    &nbsp;
                                </td>
                                <td className="text-[#000000] font-Inter font-semibold">
                                    &nbsp;
                                </td>
                            </tr>
                            {userRole.toLowerCase() !== 'fieldagent' &&
                                selectedntryDetailStatus !== 4 && (
                                    <tr className="bg-[#DDE7F1] border-t border-t-[#999090] border-b border-b-[#999090]">
                                        <td className=""></td>
                                        <td className=""></td>
                                        <td className=""></td>
                                        <td className="text-[#023E7D] font-Inter font-semibold text-right md:pr-10">
                                            Grand Total
                                        </td>
                                        <td className="text-[#000000] font-Inter font-semibold">
                                            &#8358;{' '}
                                            {Number(grandTotal).toLocaleString(
                                                'en-US'
                                            )}
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                </div>

                <div className="flex justify-center">
                    <div className="my-6 gap-6 flex">
                        {organizationTypeId === 2 &&
                            openTab !== 8 && openTab !== 7 &&
                            userRole.toLowerCase() === 'billingofficer' && (
                                <ButtonOutlined
                                    type="button"
                                    label={'Remove'}
                                    disabled={
                                        selectedntryDetail.invoiceStatus !== 0
                                    }
                                    handleClick={() => {
                                        setComment('')
                                        setEntryDetail(false)
                                        setShowReason(true)
                                    }}
                                />
                            )}
                        {organizationTypeId === 2 && openTab !== 7 &&
                            userRole.toLowerCase() === 'internalcontrol' && (
                                <ButtonOutlined
                                    type="button"
                                    label={'Decline'}
                                    disabled={
                                        selectedntryDetail.invoiceStatus !== 0
                                    }
                                    handleClick={() => {
                                        setEntryDetail(false)
                                        setShowConfirmation(true)
                                        setConfirmType('decline')
                                    }}
                                />
                            )}

                        {organizationTypeId === 2 &&
                            openTab !== 8 && openTab !== 7 &&
                            userRole.toLowerCase() === 'billingofficer' && (
                                <ButtonPrimary
                                    // isLoading={
                                    //     organizationTypeId === 2
                                    //         ? reconcileInternalLoading
                                    //         : airlineAccepting
                                    // }
                                    type="button"
                                    label={isEditMode?'Save': 'Edit'}
                                    disabled={
                                        selectedntryDetail.invoiceStatus !== 0
                                    }
                                    handleClick={() => {
                                        if(isEditMode){

                                             setShowConfirmation(true)
                                            setConfirmType('edit')
                                        }else{

                                            setIsEditMode(true)
                                            setUserActionType('EDITED BY')
                                        }
                                        // check login user and call corresponding endpoint
                                    }}
                                />
                            )}
                        {organizationTypeId === 2 && openTab !== 7 &&
                            userRole.toLowerCase() === 'internalcontrol' && (
                                <ButtonPrimary
                                    // isLoading={
                                    //     organizationTypeId === 2
                                    //         ? reconcileInternalLoading
                                    //         : airlineAccepting
                                    // }
                                    type="button"
                                    label={'Approve'}
                                    disabled={
                                        selectedntryDetail.invoiceStatus !== 0
                                    }
                                    handleClick={() => {
                                        setShowConfirmation(true)
                                        setConfirmType('approve')

                                        // check login user and call corresponding endpoint
                                    }}
                                />
                            )}
                        {organizationTypeId === 2 &&
                            openTab === 8 &&
                            userRole.toLowerCase() === 'billingofficer' && (
                                <ButtonPrimary
                                    // isLoading={
                                    //     organizationTypeId === 2
                                    //         ? reconcileInternalLoading
                                    //         : airlineAccepting
                                    // }
                                    type="button"
                                    label="Cancel "
                                    disabled={
                                        selectedntryDetail.invoiceStatus !== 0
                                    }
                                    handleClick={() => {
                                        setComment('')
                                        setShowReason(true)
                                        setConfirmType('cancel')
                                        // check login user and call corresponding endpoint
                                    }}
                                />
                            )}
                    </div>
                </div>

                {selectedntryDetail.invoiceStatus !== 0 && (
                    <span className="text-sm text-slate-500 text-center">
                        This record has been invoiced
                    </span>
                )}

                <div className="flex justify-end mt-1">
                    <span
                        className="text-[#023E7D] text-xs underline cursor-pointer"
                        onClick={() =>
                            navigate(
                                `/transactions/details/${selectedntryDetail?.id}`
                            )
                        }
                    >
                        See More
                    </span>
                </div>
            </div>
        </AppModal>
    )
}

export default EntryDetails
