import { ReactElement } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { AccessDenied } from './AccessDenied/AccessDenied'
import useNetwork from '../../hooks/useNetwork'
import { isAuthenticated } from '../../utils/IsAuthenticated'
type RouteProps = {
    comp: ReactElement
    isAllowed?: boolean
}
export const Access = ({ isAllowed, comp }: RouteProps) => {
    const isOnline = useNetwork()

    if (!isAuthenticated() && isOnline) {
        return <Navigate to="/" />
    }

    if (!isAllowed) {
        return <AccessDenied showModal={true} />
    }
    return comp ? comp : <Outlet />
}
