import { ReactElement, useContext } from 'react'
import { UiContext } from '../../../../context/uiContext'
import { useNavigate } from 'react-router-dom'
import MenuItem from '../MenuItem'
import overViewIcon from '../../../../assets/img/overView.svg'
import contractIcon from '../../../../assets/img/contract.svg'
import disputes from '../../../../assets/img/disputes.svg'
import myTasks from '../../../../assets/img/myTask.svg'
import workFlowIcon from '../../../../assets/img/workFlow.svg'
import resourcesIcon from '../../../../assets/img/resources.svg'
import auditTrail from '../../../../assets/img/audits.svg'
import settlementsIcon from '../../../../assets/img/settlements.svg'
import dataCaptureIcon from '../../../../assets/img/dataCapture.svg'
import supportIcon from '../../../../assets/img/support.svg'
import ccpIcon from '../../../../assets/img/ccp-icon.svg'
import sahcolLogo from '../../../../assets/img/logo.svg'
import {
    getSavedState,
    getUserPermissions,
} from '../../../../utils/localStorage'
import useNetwork from '../../../../hooks/useNetwork'

function Menu(): ReactElement {
    const isOnline = useNetwork()
    const permissions = getUserPermissions()
    const GHOSTUser = getSavedState('GHOSTUser')
    const OFFLINE_ORG = getSavedState('OFFLINE_ORG')
    const { organizationTypeId: offlineOrgTypeId, userRole: offlineUserRole } =
        OFFLINE_ORG
    const orgTypeId = isOnline
        ? GHOSTUser?.organizationTypeId
        : offlineOrgTypeId
    const currentUserRole = isOnline ? GHOSTUser?.userRole : offlineUserRole

    const navigate = useNavigate()
    const { currentTab, setCurrentTab, showDropDown, setToggleSidebar } =
        useContext(UiContext)
    const currentTabHandler = (name: string) => {
        setCurrentTab(name)
    }
    const DataCaptureDD = [
        {
            name: 'Activity Log',
            url: '/data-capture/activity-log',
            canAccess: permissions.has('activity_log'),
        },
        {
            name: 'Data Entry',
            url: '/data-capture/data-entry',
            canAccess: permissions.has('data_entry'),
        },
        {
            name: 'Data Processing',
            url: '/data-capture/data-process',
            canAccess: permissions.has('data_processing'),
        },
    ]
    const SettlementDD = [
        {
            name: 'Payments',
            url: '/settlements/payments',
            canAccess: permissions.has('settlements_payment'),
        },
        {
            name: 'Reconcilliations',
            url: '/settlements/reconcilation',
            canAccess: permissions.has('settlements_reconcilliation'),
        },
        // { name: 'Statements', url: '/settlements/statements', canAccess: true },/
        {
            name: 'Refunds',
            url: '/settlements/refunds',
            canAccess: permissions.has('settlements_refund'),
        },
    ]
    const ReportsDD = [
        {
            name: 'Dispute Report',
            url: '/reports/dispute',
            canAccess: permissions.has('reports'),
        },
        {
            name: 'Payment Report',
            url: '/reports/payment',
            canAccess: permissions.has('reports'),
        },
        {
            name: 'Settlement Report',
            url: '/reports/settlement',
            canAccess: permissions.has('reports'),
        },
    ]
    const SettingstDD = [
        {
            name: 'Organization Settings',
            url: '/settings/organization',
            canAccess: permissions.has('settings-organizations'),
        },
        {
            name: 'Contract Settings',
            url: '/settings/contract',
            canAccess: permissions.has('settings-contract'),
        },
        {
            name: 'Contract Service',
            url: '/settings/contract-service',
            canAccess: permissions.has('contract_services'),
        },
        {
            name: 'Roles and Permission',
            url: '/settings/roles',
            canAccess: permissions.has('settings_roles_permissions'),
        },
        {
            name: 'User Manager',
            url: '/settings/user',
            canAccess: permissions.has('settings_user'),
        },
        {
            name: 'Asset Management',
            url: '/settings/asset',
            canAccess: permissions.has('settings_asset'),
        },
    ]
    return (
        <div className="h-[calc(100vh_-_62px)] overflow-y-scroll relative scrollbar-hide">
            <ul className="pb-12">
                {permissions.has('transactions') &&
                    (currentUserRole.toLowerCase() === 'stationmanager' ||
                        currentUserRole.toLowerCase() === 'hq' ||
                        (currentUserRole.toLowerCase() === 'fieldagent')) && (
                        <MenuItem
                            name="Transaction History"
                            path="history"
                            icon={overViewIcon}
                            menuAction={() => navigate('/transactions')}
                            currentTabHandler={currentTabHandler}
                        />
                    )}

                {permissions.has('billing') &&
                    (currentUserRole.toLowerCase() === 'billingofficer' ||
                        currentUserRole.toLowerCase() === 'internalcontrol') && (
                        <MenuItem
                            name="Billing"
                            path="billing"
                            icon={overViewIcon}
                            menuAction={() => navigate('/billing')}
                            currentTabHandler={currentTabHandler}
                        />
                    )}
                {/* {permissions.has('contract') && (
                    <MenuItem
                        name="Contract"
                        path="contract"
                        icon={contractIcon}
                        menuAction={() => {
                            navigate('/contract')
                            setToggleSidebar(false)
                        }}
                        currentTabHandler={currentTabHandler}
                        // dropDown={true}
                    />
                )} */}

                {permissions.has('data_capture') && (
                    <MenuItem
                        name="Data Capture"
                        path="data-capture"
                        icon={dataCaptureIcon}
                        menuAction={() => navigate('/data-capture')}
                        currentTabHandler={currentTabHandler}
                        // menuAction={() => null}
                        // options={DataCaptureDD}
                        // dropDown={true}
                    />
                )}
                {permissions.has('data_reconcilliation') && (
                    <MenuItem
                        name="Data Reconcilliation"
                        path="reconcilliation-type"
                        icon={myTasks}
                        menuAction={() => {
                            navigate('/reconcilliation-type')
                            setToggleSidebar(false)
                        }}
                        currentTabHandler={currentTabHandler}
                    />
                )}

                {permissions.has('audit_trail') && (
                    <MenuItem
                        name="Audit Trail"
                        path="audit"
                        icon={auditTrail}
                        menuAction={() => {
                            navigate('/audit')
                            setToggleSidebar(false)
                        }}
                        currentTabHandler={currentTabHandler}
                    />
                )}
                {permissions.has('invoices') && (
                    <MenuItem
                        name="Invoices"
                        path="invoice"
                        icon={auditTrail}
                        menuAction={() => {
                            navigate('/invoice')
                            setToggleSidebar(false)
                        }}
                        currentTabHandler={currentTabHandler}
                    />
                )}
                {/* {permissions.has('resources') && (
                    <MenuItem
                        name="Resources"
                        path="resources"
                        icon={resourcesIcon}
                        menuAction={() => {
                            navigate('/resources')
                            setToggleSidebar(false)
                        }}
                        currentTabHandler={currentTabHandler}
                    />
                )} */}
                {permissions.has('dashboard') && (
                    <MenuItem
                        name="Dashboard"
                        path="dashboard"
                        icon={resourcesIcon}
                        menuAction={() => {
                            navigate('/dashboard')
                            setToggleSidebar(false)
                        }}
                        currentTabHandler={currentTabHandler}
                    />
                )}
                {/* {permissions.has('workflow_approval') && (
                    <MenuItem
                        name="Workflow Approval"
                        path="workflow"
                        icon={workFlowIcon}
                        menuAction={() => {
                            navigate('/workflow')
                            setToggleSidebar(false)
                        }}
                        currentTabHandler={currentTabHandler}
                    />
                )} */}

                {permissions.has('settings') && (
                    <MenuItem
                        name="Settings"
                        path="settings"
                        icon={resourcesIcon}
                        menuAction={() => null}
                        // menuAction={() => navigate('/settings')}
                        currentTabHandler={currentTabHandler}
                        options={SettingstDD}
                        dropDown={true}
                    />
                )}
                {permissions.has('maker_checker') && (
                    <MenuItem
                        name="Maker Checker"
                        path="maker-checker"
                        icon={resourcesIcon}
                        menuAction={() => {
                            navigate('/maker-checker')
                            setToggleSidebar(false)
                        }}
                        currentTabHandler={currentTabHandler}
                    />
                )}
            </ul>
            <div className="h-40 w-full flex flex-col justify-center pt-12 bottom-0">
                <div className="flex justify-center items-center pb-4">
                    <p className="text-[#023E7D] font-semibold text-sm mt-4">
                        POWERED BY:
                    </p>
                    &nbsp; &nbsp;
                    <img
                        src={sahcolLogo}
                        alt="ccp"
                        className="bg-purple w-14"
                    />
                </div>
                <div className="flex flex-col items-center gap-2 w-full border-t pt-4">
                    <span className="text-bold text-[#677684]">Support?</span>
                    <div className=" shadow-xl rounded-full w-10 flex items-center justify-center">
                        <img
                            src={supportIcon}
                            alt="support"
                            className="bg-purple w-8"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Menu
