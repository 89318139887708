import { useState } from 'react'
import { useQuery } from 'react-query'
import { getLocationByOrganizationId } from '../utils/services//settings/organization'
import { fetchInvoiceStatus } from '../utils/services/invoice'

export default function useInvoiceStatus() {
    const [invoiceStatus, setInvoiceStatus] = useState([])

    const { refetch } = useQuery(['GetInvoiceStatus'], () => fetchInvoiceStatus(), {
        enabled: true,
        retry: 2,
        onSuccess: (response) => {
            setInvoiceStatus(response.data)
        },
    })

    const getInvoiceStatus = () => {
        refetch();
    }

    return { invoiceStatus, getInvoiceStatus }
}
