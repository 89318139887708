import { FC } from 'react'

import cardIcon from '../../../assets/img/TransactionHistory/card-icon.svg'

export const OverviewCard: FC<{ type: string; amount: string }> = ({
    type,
    amount,
}) => {
    return (
        <div className="flex flex-col md:flex-row md:gap-6 gap-3 mt-4 ">
            <div className="w-full h-[178px] rounded-md border-4 border-slate-400 p-4 flex flex-col justify-between bg-white">
                <img src={cardIcon} alt="icon" className="w-4" />
                <p className=" text-[#181515] font-Inter font-semibold text-sm">
                    {type}
                </p>
                <p className="text-[#181515] font-Inter text-2xl font-bold">
                    {amount}
                </p>
            </div>
        </div>
    )
}
