import { getAllContractByOrg } from '../services/contract'
import { db } from './localbase'
import { Contract } from './localbase.types'

export async function bulkAddContractById() {
    //fetch and persist org contracts
    const contractResp = await getAllContractByOrg()

    if (contractResp.data) {
        try {
            let updatedContract: Contract[] = contractResp.data?.map((item: Contract) => {
                return {
                    ...item,
                    organizations: JSON.stringify(item?.organizations),
                }
            })

            await db.contractById.bulkAdd(updatedContract)
        } catch (ex) {
            console.log('error in bulk add org contracts', ex)
        }
    }
}

export async function getSingleContractById(contractId: string) {

    let contract
    try {
        contract = await db.contractById.get(contractId)
        return contract
    } catch (error) {
        console.log('Error reading contract by id', error)
    }
}
