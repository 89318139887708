import { getSavedState } from '../localStorage';
import { getContractServiceByOrganization } from '../services/contract'
import { setupFlights } from './flights';
import { db } from './localbase'

//fetch and perist partners
export async function setupContractServiceByOrg() {
    const userToken = getSavedState('GHOSTUser')
    const airlineByOrgResponse = await getContractServiceByOrganization()

    let partners = airlineByOrgResponse.data;

    let partnerIds;

    if (userToken?.organizationTypeId === 1) {
        partnerIds = [userToken?.organizationId]
    }

    if (userToken?.organizationTypeId === 2) {
        partnerIds = partners.map((item: { organizationId: any; }) => {
            return item.organizationId
        })
    }
  
   await setupFlights(partnerIds) 

    if (airlineByOrgResponse?.data) {
        console.log("organizations", airlineByOrgResponse.data);
        
        try {
            await db.airlineOrg.bulkAdd(airlineByOrgResponse?.data)
        } catch (ex) {}
    }
}

export async function getAirlineOrg() {
    const airlineOrg = await db.airlineOrg.toArray()
    return airlineOrg
}

export async function clearAirlineOrg() {
    await db.airlineOrg.clear()
}

