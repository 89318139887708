import { devInstance } from '../axios/axiosInstance'

export const searchContractSettings = async (payload: any) => {
    const response = await devInstance.post('/Contract/search', payload)
    return response
}

export const getOperationType = async (payload: any) => {
    const response = await devInstance.get('/Setting/GetOperationType', payload)
    return response
}

export const getServiceCategory = async () => {
    const response = await devInstance.get('/Setting/GetServiceCategory')
    return response
}

export const uploadFile = async (payload: any) => {
    const formData = new FormData()
    formData.append('file', payload.file)

    const response = await devInstance.post('/Setting/Upload', formData)
    return response
}
