export const getSavedState = (itemKey: string) => {
    let savedState = {}
    try {
        let savedItem = localStorage.getItem(itemKey)
        if (typeof savedItem === 'string') {
            return JSON.parse(savedItem)
        }
        return savedState
    } catch (error) {
        
        
        return savedState
    }
}

export const getUserPermissions = (): Set<string> => {
    const userInfo = getSavedState('GHOSTUser')
    if (userInfo && userInfo.permission) {
        return new Set(userInfo.permission)
    }
    return new Set([])
}

export async function saveLocalState(itemKey: string, itemValue: any) {
    localStorage.setItem(itemKey, JSON.stringify(itemValue))
}

export function deleteLocalState(itemKey: string) {
    localStorage.removeItem(itemKey)
}
