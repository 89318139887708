import { ReactElement } from 'react'
import sahcol from '../../../assets/img/TransactionHistory/sahco-logo.png'
import ghostLogo from '../../../assets/img/logo.svg'
import { getSavedState } from '../../../utils/localStorage';
import Menu from './Menu'

function AdminSidebar(): ReactElement {
    const GHOSTUser = getSavedState('GHOSTUser')
    const { organizationLogo } = GHOSTUser || {};

    return (
        <aside className="w-[220px] bg-white min-h-screen fixed top-0 left-0 hidden lg:block">
            <div className="p-4 flex justify-between items-center">
                <div className="w-[84px] h-[50px] lg:pl-2">
                    <img src={organizationLogo? organizationLogo : ghostLogo} alt="logo" />
                </div>
            </div>

            <Menu />
        </aside>
    )
}

export default AdminSidebar
