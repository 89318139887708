
    import { getSavedState } from './localStorage'

    const GHOSTUser = getSavedState("GHOSTUser")

    export const isAuthenticated = () => {
        return Object.keys(GHOSTUser).length > 0
    }


