import React, { ReactElement, useState, useEffect } from 'react'
import GenericLayout from '../../components/GenericLayout'
import { DatePicker } from '../../components/UIKit/DatePicker/Date'
import { downloadAudit } from '../../utils/services/auditTrail'
import { useQuery, useMutation } from 'react-query'
import moment from 'moment'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { downloadFile } from '../../utils/downloadFile'
import { Link, useNavigate } from 'react-router-dom'
import { Input } from '../../components/UIKit/Input/Input'
import Select from 'react-select'
import { customStyles } from '../../utils/selectStyles'
import { createInvoice, searchInvoice } from '../../utils/services/invoice'
import useLocationByOrgId from '../../hooks/useLocationByOrgId'
import { getSavedState } from '../../utils/localStorage'
import useInvoiceStatus from '../../hooks/useInvoiceStatus'
import usePartners from '../../hooks/usePartners'
import useCurrency from '../../hooks/useCurrency'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { ButtonPrimary } from '../../components/UIKit/ButtonPrimary/Button'
import { BackArrow } from '../../components/Icons/BackArrow'
import ViewInvoice from '../../components/Invoice/ViewInvoice'
import { AppModal } from '../../components/UserSettings/Modal'
import PreviewInvoice from './Preview'
import { responseErr } from '../../utils/axios/responseInterceptor'

const schema = yup.object().shape({
    startDate: yup.string().required('Start date is required'),
    endDate: yup.string().required('End date is a required field'),
    clientId: yup.string().required('customer is a required field'),
    locationId: yup.string().required('location is a required field'),
})

export default function CreateInvoice(): ReactElement {
    const savedState = getSavedState('GHOSTUser')
    const navigate = useNavigate()

    const { locations, getLocByOrgId } = useLocationByOrgId(
        savedState?.organizationId
    )
    const { invoiceStatus, getInvoiceStatus } = useInvoiceStatus()
    const { customers, getCustomers } = usePartners()
    const [showModal, setShowModal] = useState(false)
    const [invoiceInfo, setInvoiceInfo] = useState({})
    const [payload, setPayload] = useState({})
    const { currency, getAllCurrencies } = useCurrency()
    // const [customers] = useState([{ name: 'Avitech', id: '1' }])

    const { mutateAsync, isLoading, isError, error, data } = useMutation(
        createInvoice,
        {
            onSuccess: (response) => {
                setShowModal(true)
                setInvoiceInfo(response.data)

                // toast.success(`${response?.data?.message || 'Successful'}`)
            },
            onError: (error: AxiosError) => {
                toast.error(error?.response?.data?.errors[0])
            },
        }
    )

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),

        defaultValues: {
            startDate: '',
            endDate: '',
        },
    })
    const onSubmit = (data: any) => {
        setPayload(data)
        console.log('anything');
        
        mutateAsync({
            ...data,
            isPreview: true,
        })
    }

    // useEffect(() => {
    //     refetch()
    // }, [])
    useEffect(() => {
        getLocByOrgId()
        getCustomers()
        getInvoiceStatus()
    }, [])

    return (
        <GenericLayout breadcrumbs={[<Link to="/audit">Invoice</Link>]}>
            <div className="p-6 bg-[#F1F2F3]">
                <div className="flex justify-between">
                    <p className="font-bold text-2xl text-[#002555]">
                        Create Invoice
                    </p>
                    <div className="flex items-start justify-end   ">
                        <div
                            className="flex gap-2 cursor-pointer bg-[#023E7D] items-center border border-white hover:bg-[#023d7ddf] rounded-lg px-2"
                            onClick={() => navigate(-1)}
                        >
                            <BackArrow className="stroke-white w-3" />
                            <p className="text-white text-xs">Back</p>
                        </div>
                    </div>
                </div>
                <form className="mb-12" onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-full md:pl-8 md:flex md:flex-wrap md:gap-4 md:justify-between items-center">
                        {/* <div className="mt-3 w-full md:w-[calc(48.6%)]">
                            <label className="font-Inter font-normal text-[#3A434B] text-sm">
                                Select Status
                            </label>
                            <Select
                                placeholder="Select"
                                options={invoiceStatus?.map(
                                    (item: { id: string; name: string }) => {
                                        return {
                                            value: item?.id,
                                            label: item?.name,
                                        }
                                    }
                                )}
                                onChange={
                                    (newValue: any) => {
                                        console.log(newValue)
                                    }
                                    // setValue('status', newValue.value)
                                }
                                // defaultValue={values.industry}
                                styles={customStyles}
                            />
                            <p className="text-xs text-red-600">
                                {errors?.status?.message}
                            </p>
                        </div>  */}

                        <div className="mt-3 w-full md:w-[calc(48.6%)]">
                            <label className="font-Inter font-normal text-[#3A434B] text-sm">
                                Start Date
                            </label>

                            <Input
                                placeholder=""
                                loading={false}
                                register={register('startDate', {
                                    shouldValidate: true,
                                })}
                                name="startDate"
                                type="date"
                            />
                            <p className="text-xs text-red-600">
                                {errors?.startDate?.message}
                            </p>
                        </div>
                        <div className="mt-3 w-full md:w-[calc(48.6%)]">
                            <label className="font-Inter font-normal text-[#3A434B] text-sm">
                                End date
                            </label>
                            <Input
                                placeholder=""
                                loading={false}
                                register={register('endDate', {
                                    shouldValidate: true,
                                })}
                                name="endDate"
                                type="date"
                            />
                            <p className="text-xs text-red-600">
                                {errors?.endDate?.message}
                            </p>
                        </div>
                        <div className="mt-3 w-full md:w-[calc(48.6%)]">
                            <label className="font-Inter font-normal text-[#3A434B] text-sm">
                                Select Partner or Customer
                            </label>
                            <Select
                                placeholder="Select"
                                options={customers?.map((item: any) => {
                                    return {
                                        value: item.organizationId,
                                        label: item?.organizationName,
                                    }
                                })}
                                onChange={(newValue: any) =>
                                    setValue('clientId', newValue.value, {
                                        shouldValidate: true,
                                    })
                                }
                                // defaultValue={values.industry}
                                styles={customStyles}
                            />
                            <p className="text-xs text-red-600">
                                {errors?.clientId?.message}
                            </p>
                        </div>
                        <div className="mt-3 w-full md:w-[calc(48.6%)]">
                            <label className="font-Inter font-normal text-[#3A434B] text-sm">
                                Location
                            </label>
                            <Select
                                placeholder="Select"
                                options={locations?.map((item: any) => {
                                    return {
                                        value: item.locationId,
                                        label: item.locationName,
                                    }
                                })}
                                onChange={(newValue: any) =>
                                    setValue('locationId', newValue.value, {
                                        shouldValidate: true,
                                    })
                                }
                                // defaultValue={values.industry}
                                styles={customStyles}
                            />
                            <p className="text-xs text-red-600">
                                {errors?.locationId?.message}
                            </p>
                        </div>

                        <div className="mt-3 w-full md:w-[calc(48.6%)]">
                            <label className="font-Inter font-normal text-[#3A434B] text-sm">
                            Select Currency Type
                            </label>
                            <span className="mr-5 text-[#F3477A]">
                                        *
                                    </span>
                                    <Select
                                    placeholder="Naira"
                                    options={currency?.map(
                                        (ele: { id: any; name: any }) => {
                                            return {
                                                value: ele.id,
                                                label: ele.name,
                                            }
                                        }
                                    )}
                                    onChange={(newValue: any) =>
                                        setValue('currencyId', newValue.value, {
                                            shouldValidate: true,
                                        })
                                    }
                                    //@ts-ignore
                                    styles={customStyles}
                                />
                            <p className="text-xs text-red-600">
                                    {errors?.currencyType?.value?.message}
                                </p>
                        </div>
                        {savedState?.organizationTypeId === 2 && (
                            <div className="flex justify-end items-end  mt-5 w-full">
                                <ButtonPrimary
                                    label="Create Invoice"
                                    isLoading={isLoading}
                                />
                            </div>
                        )}
                    </div>
                </form>
            </div>
            <AppModal
                isOpen={showModal}
                setIsOpen={setShowModal}
                closeButtonClass="-right-10 top-0"
                contentClass="py-0"
                containerClass="w-full max-w-[800px] "
            >
                <PreviewInvoice
                    invoiceInfo={invoiceInfo && invoiceInfo}
                    setIsOpen={setShowModal}
                    payload={payload}
                />
            </AppModal>
        </GenericLayout>
    )
}
