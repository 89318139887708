import { AxiosError } from 'axios'
import React, { Dispatch } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { cancelBillUpdate } from '../../../utils/services/transaction'
import { ButtonOutlined } from '../../UIKit/ButtonOutlined/Button'
import { ButtonPrimary } from '../../UIKit/ButtonPrimary/Button'
import { Input } from '../../UIKit/Input/Input'
import { AppModal } from '../../UserSettings/Modal'

export type EditModalProps = {
    comment?: string
    setComment?: any
    showReason?: boolean
    setShowReason: Dispatch<React.SetStateAction<boolean>>
    showConfirmation?: boolean
    setShowConfirmation: Dispatch<React.SetStateAction<boolean>>
    setOpenEditAmountModal: Dispatch<React.SetStateAction<boolean>>
    lineItem?: any
    setLineItem?: Dispatch<React.SetStateAction<any>>
    setShowSuccess?: any
    confirmType?: string
    selectedntryDetail?: any
    refetch?: any
    setConfirmType: any
    setEntryDetail?: any
}
const EnterReason = (props: EditModalProps) => {
    const {
        comment,
        setComment,
        showReason,
        setShowReason,
        setShowConfirmation,
        refetch,
        setShowSuccess,
        lineItem,
        setConfirmType,
        confirmType,
    } = props

    return (
        <AppModal
            title=""
            isOpen={showReason!}
            setIsOpen={setShowReason}
            containerClass="w-full   max-w-2xl"
            contentClass="h-auto rounded-lg lineItems-center"
            showCloseButton={false}
            childrenContainer="w-full rounded-lg flex flex-col p-6"
        >
            {' '}
            <div className="flex justify-between">
                <p className="font-Inter font-bold text-[#5B5C60]  text-[18px]">
                    {confirmType === 'cancel' ? 'Cancel ' : 'Remove'}
                </p>
                <span
                    className="font-bold text-[#ccc] cursor-pointer"
                    onClick={() => setShowReason(false)}
                >
                    X
                </span>
            </div>
            <div className="flex flex-col my-5">
                <label htmlFor="" className="text-[#787885] mb-1">
                    Reason{' '}
                </label>
                <textarea
                    placeholder=" "
                    className="h-[99px] border-0 rounded-md bg-[#F9F9F9]"
                    onChange={(e) => !setComment(e.target.value)}
                    value={comment}
                />
            </div>
            <div className="mb-4 gap-6 flex justify-between">
                <ButtonOutlined
                    isLoading={false}
                    type="button"
                    label="Back"
                    handleClick={() => {
                        setShowReason(false)
                        // setSho(false)
                    }}
                />
                <ButtonPrimary
                    isLoading={false}
                    type="button"
                    label="Save"
                    handleClick={() => {
                        if ((comment?.length as number) < 4) {
                            toast('Comment cannot be less than 4 characters')
                            return
                        }
                        setShowReason(false)
                        setShowConfirmation(true)
                        if (confirmType === 'cancel') {
                            setConfirmType('cancel')
                        } else {
                            setConfirmType('remove')
                        }
                    }}
                />
            </div>
        </AppModal>
    )
}

export default EnterReason
