import { basename } from 'path/posix'

//react select custom styles
export const customStyles = {
    control: (base: any) => ({
        ...base,
        '*': {
            boxShadow: 'none !important',
        },
        minHeight: '44px',
        height: 'auto',
        fontSize: '13px',
    }),
    singleValue: (base: any) => ({
        ...base,
        color: '#3A434B',
        opacity: '1',
    }),
    placeholder: (defaultStyles: any) => {
        return {
            ...defaultStyles,
            color: '#3A434B',
            fontSize: '13px',
        }
    },
}

export const smallSelect = {
    ...customStyles,
    control: (base: any) => ({
        ...base,
        height: '30px',
        '*': {
            boxShadow: 'none !important',
        },
        fontSize: '11px',
        width: '110px',
        minHeight: '30px',
    }),
    indicatorsContainer: (base: any) => ({
        ...base,
        height: '30px',
    }),
    placeholder: (base: any) => ({
        ...base,
        fontSize: '11px',
    }),
}
// export const customStylesMulti = {
//     control: (base: any) => ({
//         ...base,
//         '*': {
//             boxShadow: 'none !important',
//         },
//         minHeight: '44px',
//         height: 'auto',
//         fontSize: '13px',
//     }),
//     singleValue: (base: any) => ({
//         ...base,
//         color: '#3A434B',
//         opacity: '1',
//     }),
//     placeholder: (defaultStyles: any) => {
//         return {
//             ...defaultStyles,
//             color: '#3A434B',
//             fontSize: '13px',
//         }
//     },
// }
