import { FC, useEffect, useState } from 'react'
import moment from 'moment'
import fileIcon from '../../../assets/img/TransactionHistory/file.svg'
import approveIcon from '../../../assets/img/TransactionHistory/approval.svg'
import disputeFlag from '../../../assets/img/TransactionHistory/disputedFlag.svg'
import capturedFlag from '../../../assets/img/TransactionHistory/capturedFlag.svg'
import acceptedFlag from '../../../assets/img/TransactionHistory/acceptedFlag.svg'
import approvedFlag from '../../../assets/img/TransactionHistory/approvedFlag.svg'
import notUploaded from '../../../assets/img/TransactionHistory/notUploaded.svg'

import { SideChat } from '../../DataReconcilliation/SideChat'
import { useMutation, useQuery } from 'react-query'
import {
    addNewDataEntry,
    createEntryChat,
    getEntryChat,
} from '../../../utils/services/dataEntry'
import { AxiosError } from 'axios'
import { AppModal } from '../../UserSettings/Modal'
import { ButtonPrimary } from '../../UIKit/ButtonPrimary/Button'
import { ButtonOutlined } from '../../UIKit/ButtonOutlined/Button'
import { toast } from 'react-toastify'
import { getSavedState } from '../../../utils/localStorage'
import useNetwork from '../../../hooks/useNetwork'
import { deleteEntry } from '../../../utils/localbase/offlineDataCapture'
import { useNavigate } from 'react-router-dom'
import EditAmountModal from '../Modals/EditAmountModal'
import EnterReason from '../Modals/EnterReasonModal'
import ConfrimationModal from '../Modals/ConfrimationModal'
import EntryDetails from '../Modals/EntryDetails'
import ShowSuccesModal from '../Modals/ShowSuccesModal'

export interface CardDataType {
    // userName: string
    // email: string
    // ipAddress: string
    // description: string
    // createdDate: string
    time?: string
    operationDate?: string
    scenerioCaseName?: string
    clientName?: string
    referenceNumber?: string
    handlingType?: string
    airlineCode?: string
    grandTotal?: string
    status?: number
    metadata?: {
        serviceType: string
        amount: number
        servicePeriod: number
        servicePeriodHourlyStart: string
        servicePeriodHourlyEnd: string
        index?: number
    }[]
    OperationType?: string
    producerName?: string
    consumerName?: string
}

export const HistoryCardList: FC<{
    cardList: CardDataType[]
    refetch?: any
    openTab?: any
    fetchOfflineData?: any
}> = ({ cardList, refetch, openTab, fetchOfflineData }) => {
    const userToken = getSavedState('GHOSTUser')
    const { userRole, organizationTypeId } = userToken
    const navigate = useNavigate()

    const isOnline = useNetwork()
    const [editEntryModal, setEditEntryModal] = useState<any>()
    const [lineItem, setLineItem] = useState<any>()
    const [amount, setAmount] = useState<any>()
    const [editAmountModal, setEditAmountModal] = useState<boolean>(false)
    const [showApproveModal, setShowApproveModal] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [confirmType, setConfirmType] = useState('')
    const [sideModal, setSideModal] = useState<any>()
    const [entryDetail, setEntryDetail] = useState<any>()
    const [showReason, setShowReason] = useState<any>()
    const [openEditAmountModal, setOpenEditAmountModal] =
        useState<boolean>(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [selectReason, setselectReason] = useState<any>()
    const [comment, setComment] = useState<string>('')

    const [selectedntryDetail, setSelectedntryDetail] = useState<any>()
    const [selectedntryDetailStatus, setSelectedntryDetailStatus] =
        useState<number>(0)
    const [entryIdx, setentryIdx] = useState<number>(0)
    const [metaDataIdx, setMetaDataIdx] = useState<number>(0)

    // console.log({ selectedntryDetail })
 
    const [cardListCopy, setcardListCopy] = useState<CardDataType[]>([])

    useEffect(() => {
        if (cardList?.length > 0) {
            const updatedData = cardList.reduce(
                (acc: any, item: any, idx: number) => {
                    let newItem = {
                        ...item,
                        metadata: item?.metadata?.map(
                            (metadataItem: any, index: any) => {
                                return {
                                    ...metadataItem,
                                    index,
                                    // rate:
                                }
                            }
                        ),
                    }
                    acc.push(newItem)
                    return acc
                },
                []
            )
            setcardListCopy(updatedData)
        }
    }, [cardList])

    const handleClickOpen = (index: number) => {
        setentryIdx(index)
        setSelectedntryDetail(cardListCopy[index])
        setSelectedntryDetailStatus(cardListCopy[index]?.status || 0)
        setEntryDetail(true)
    }
    const getChatPayload = {
        Source: 1,
        Page: 1,
        PageSize: 10,
        SourceId: selectedntryDetail?.id,
    }

    const {
        mutateAsync: createChat,
        isLoading: createChatLoading,
        isError: createChatError,
    } = useMutation(createEntryChat, {
        onSuccess: ({ data }) => {
            refetchGetChat()
        },
        onError: (error: AxiosError) => {
            // toast.error(error?.response?.data?.errors[0])
        },
    })

     

    


     

    

    

    const {
        isLoading: chatLoading,
        isError,
        data: chatData,
        error,
        refetch: refetchGetChat,
    } = useQuery([selectedntryDetail?.id], () => getEntryChat(getChatPayload), {
        enabled: true,
        retry: false,
        onSuccess: (res) => {},
        onError: (err) => {},
    })

    const grandTotal = selectedntryDetail?.metadata?.reduce(
        (acc: any, item: any) => {
            acc = acc + parseInt(item?.amount)
            return acc
        },
        0
    )
    const CardListItem: FC<{
        cardData: CardDataType
        index: number
        openTab?: any
    }> = ({ cardData, index, openTab }) => (
        <div className="bg-white w-full min-w-full rounded px-5 py-8 my-5 flex justify-between shadow-md last:border-none scrollbar-hide  md:overflow-auto overflow-scroll">
            <div className="text-xs px-5 min-w-fit flex flex-col justify-center">
                <div className="text-[#002555] capitalize font-semibold text-xs ">
                    {cardData?.referenceNumber}
                </div>
            </div>
            <div className="border-r text-xs border-r-gray-400 pr-7 min-w-fit">
                <div className="text-[#4F4F4F] text-xs mb-2">Date</div>
                <div className="text-[#002555] capitalize font-semibold text-xs ">
                    {moment(cardData.operationDate).format('DD/MM/YYYY')}{' '}
                </div>
            </div>
            <div className="border-r text-xs border-r-gray-400 px-5 min-w-fit w-[15%]">
                <div className="text-[#4F4F4F] text-xs mb-2">
                    Operation Type
                </div>
                <div className="text-[#002555] capitalize font-semibold text-xs ">
                    {cardData.OperationType || cardData?.handlingType}
                </div>
            </div>
            <div className="border-r border-r-gray-400 px-5 w-[20%]">
                <div className="text-[#4F4F4F] font-Inter text-xs mb-2">
                    Service type
                </div>
                <div className="text-[#002555] font-semibold font-Inter  break-words  text-xs">
                    {cardData?.scenerioCaseName}
                </div>
            </div>
            <div className="border-r text-xs border-r-gray-400 px-5 w-[12%] last:border-none">
                <div className="text-[#4F4F4F] mb-2">Aircraft</div>
                <div className="text-[#002555] font-semibold text-xs capitalize">
                    {cardData?.airlineCode}
                </div>
            </div>
            {cardData?.referenceNumber && (
                <div className="border-r text-xs border-r-gray-400 px-5 w-[15%] last:border-none">
                    <div className="text-[#4F4F4F] mb-2">Amount</div>
                    <div className="text-[#002555] text-xs font-semibold ">
                        &#8358;{' '}
                        {Number(cardData?.grandTotal).toLocaleString('en-US')}
                    </div>
                </div>
            )}
            <div className="border-r text-xs border-r-gray-400 px-5 w-[15%] last:border-none">
                <div className="text-[#4F4F4F] mb-2 text-xs">Client</div>
                <div className="text-[#002555] text-xs font-semibold">
                    {cardData?.clientName}
                    {/* {organizationTypeId === 2 ? cardData?.consumerName : cardData?.producerName} */}
                </div>
            </div>
            <div className='className="text-xs pl-5 flex flex-col justify-center w-[5%]'>
                <div className="text-[#002555] text-xs font-semibold">
                    <img src={fileIcon} alt="icon" className="w-3" />
                </div>
            </div>
            <div className='className="text-xs pl-5 flex flex-col justify-center w-[15%]'>
                <div
                    className="text-xs font-semibold flex gap-2 items-center cursor-pointer"
                    onClick={() => {
                        setSelectedntryDetail(cardData)
                        if (!cardData?.referenceNumber) {
                            return
                        } else {
                            setSideModal(true)
                        }
                    }}
                >
                    {cardData?.status === 0 && (
                        <>
                            <img
                                src={capturedFlag}
                                alt="icon"
                                className="w-6"
                            />
                            <p className="text-[#F5C26B]">Captured</p>
                        </>
                    )}
                    {cardData?.status === 5 && (
                        <>
                            <img
                                src={capturedFlag}
                                alt="icon"
                                className="w-6"
                            />
                            <p className="text-[#F5C26B]">Pending</p>
                        </>
                    )}
                    {cardData?.status === 1 && (
                        <>
                            <img
                                src={approvedFlag}
                                alt="icon"
                                className="w-6"
                            />
                            <p className="text-[#1B5BBC]">
                                {organizationTypeId === 2
                                    ? 'Approved'
                                    : 'Received'}
                            </p>
                        </>
                    )}
                    {cardData?.status === 3 && (
                        <>
                            <img
                                src={acceptedFlag}
                                alt="icon"
                                className="w-6"
                            />
                            <p className="text-[#07B475]">Accepted</p>
                        </>
                    )}
                    {cardData?.status === 4  && (
                        <>
                            <img src={disputeFlag} alt="icon" className="w-6" />
                            <p className="text-[red]">Disputed</p>
                        </>
                    )}
                     {cardData?.status === 44  && (
                        <>
                            <img src={disputeFlag} alt="icon" className="w-6" />
                            <p className="text-[red]">Disputed</p>
                        </>
                    )}
                     {cardData?.status === 6  && (
                        <>
                            <img src={disputeFlag} alt="icon" className="w-6" />
                            <p className="text-[red]">Bin</p>
                        </>
                    )}
                     {cardData?.status === 7   && (
                        <>
                            <img src={disputeFlag} alt="icon" className="w-6" />
                            <p className="text-[red]">Bin</p>
                        </>
                    )}
                     {cardData?.status === 8 && (
                        <>
                            <img src={approvedFlag} alt="icon" className="w-6" />
                            <p className="text-[#1B5BBC]">Awaiting Approval</p>
                        </>
                    )}
                    {!cardData?.referenceNumber && (
                        <>
                            <img src={notUploaded} alt="icon" className="w-6" />
                            <p className="text-[#ccc]">Not Uploaded</p>
                        </>
                    )}
                </div>
            </div>
            <div className="pl-1 flex-1 flex items-center  min-w-fit w-[10%]">
                <div
                    className="text-xs text-[#023E7D] border border-[#023E7D] rounded-md px-5 py-1 cursor-pointer"
                    onClick={() => handleClickOpen(index)}
                >
                    View
                </div>
            </div>
        </div>
    )

    const { mutateAsync: saveContractData, isLoading } = useMutation(
        addNewDataEntry,
        {
            onSuccess: async ({ data }) => {
                await deleteLocalData()

                setShowSuccess(true)
                setEntryDetail(false)
            },
            onError: (error: AxiosError) => {
                toast.error(error?.response?.data?.errors[0])
            },
        }
    )

    const handleSubmitData = async () => {
        if (selectedntryDetail?.MetaData === 0) {
            toast.error('You must select at least one to continue')
            return
        }

        const payload = {
            ContractId: selectedntryDetail?.ContractId,
            ProducerId: parseInt(selectedntryDetail?.ProducerId),
            ConsumerId: parseInt(selectedntryDetail?.ConsumerId),
            ScenerioCaseName: selectedntryDetail?.ScenerioCaseName,
            UploadedFilePath: 'file.com',
            SourceId: 1,
            RecordCount: selectedntryDetail?.RecordCount,
            MetaData: selectedntryDetail?.MetaData,
            ArrivalFlightNumber: selectedntryDetail?.ArrivalFlightNumber,
            DepartureFlightNumber: selectedntryDetail?.DepartureFlightNumber,
            ArrivalLocation: selectedntryDetail?.ArrivalLocation,
            DepartureLocation: selectedntryDetail?.DepartureLocation,
            OperationType: 'unschedule',
            Comment: selectedntryDetail?.Comment,
            ETA: selectedntryDetail?.ETA,
            ETD: selectedntryDetail?.ETD,
            OperationDate: selectedntryDetail?.OperationDate,
            airlineCode: selectedntryDetail.airlineCode,
        }

        saveContractData(payload)

        //delete from localbase
        // await deleteLocalData()
        return
    }

    const deleteLocalData = async () => {
        await deleteEntry(selectedntryDetail?.dataId)
        await fetchOfflineData()
        toast.success('Offline data deleted successfully')
    }

    return (
        <>
            <div className="overflow-x-scroll d-flex flex-col ">
                {cardListCopy.map((item, idx) => (
                    <CardListItem
                        key={idx}
                        cardData={item}
                        index={idx}
                        openTab={openTab}
                    />
                ))}
            </div>
            <SideChat
                sideModal={sideModal}
                setSideModal={setSideModal}
                chatData={chatData}
                createChat={createChat}
                refetchGetChat={refetchGetChat}
                contractDataId={selectedntryDetail?.id}
                createChatLoading={createChatLoading}
                chatLoading={chatLoading}
                isError={isError}
                
            />
            {entryDetail && (
                <EntryDetails
                    selectedntryDetail={selectedntryDetail}
                    entryDetail={entryDetail}
                    setEntryDetail={setEntryDetail}
                    selectedntryDetailStatus={selectedntryDetailStatus}
                    openTab={openTab}
                    cardListCopy={cardListCopy}
                    setShowConfirmation={setShowConfirmation}
                    setcardListCopy={setcardListCopy}
                    entryIdx={entryIdx}
                    metaDataIdx={metaDataIdx}
                    setShowReason={setShowReason}
                    setMetaDataIdx={setMetaDataIdx}
                    setEditEntryModal={setEditEntryModal}
                    setOpenEditAmountModal={setOpenEditAmountModal}
                    setLineItem={setLineItem}
                    setConfirmType={setConfirmType}
                    setComment={setComment}
                    
                />
            )}
            {showReason && (
                <EnterReason
                    comment={comment}
                    setComment={setComment}
                    setShowReason={setShowReason}
                    showReason={showReason}
                    setShowConfirmation={setShowConfirmation}
                    lineItem={lineItem}
                    setLineItem={setLineItem}
                    setOpenEditAmountModal={setOpenEditAmountModal}
                    setConfirmType={setConfirmType}
                    confirmType = {confirmType}
                    setEntryDetail= {setEntryDetail}
                />
            )}
            {openEditAmountModal && (
                <EditAmountModal
                    comment={comment}
                    setComment={setComment}
                    setOpenEditAmountModal={setOpenEditAmountModal}
                    openEditAmountModal={openEditAmountModal}
                    setShowConfirmation={setShowConfirmation}
                    lineItem={lineItem}
                    setLineItem={setLineItem}
                    amount={amount}
                    selectedntryDetail={selectedntryDetail}
                    setConfirmType={setConfirmType}
                    setSelectedntryDetail={setSelectedntryDetail}
                />
            )}
            {showConfirmation && (
                <ConfrimationModal
                    setShowReason={setShowReason}
                    showConfirmation={showConfirmation}
                    setShowConfirmation={setShowConfirmation}
                    setOpenEditAmountModal={setOpenEditAmountModal}
                    selectedntryDetail={selectedntryDetail}
                    confirmType={confirmType}
                    refetch={refetch}
                    comment={comment}
                    setShowSuccess={setShowSuccess}
                    setConfirmType={setConfirmType}
                    setEntryDetail={setEntryDetail}
                    
                />
            )}
           
            {showSuccess && (
               <ShowSuccesModal
               showSuccess={showSuccess}
               setShowSuccess={setShowSuccess}
               setEditEntryModal={setEditEntryModal}
               setOpenEditAmountModal={setOpenEditAmountModal}
               setShowReason={setShowReason}
               setShowConfirmation={setShowConfirmation}
               />
            )}
        </>
    )
}

const truncateStrring = (input: string, num: number) => {
    if (input.length < num) {
        return input
    }
    return input.slice(0, num) + '...'
}
