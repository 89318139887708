import { useState } from 'react'
import { useQuery } from 'react-query'
import { ServiceCategory } from '../utils/localbase/localbase.types'
import { getServiceCategories } from '../utils/localbase/serviceCategory'
import { fetchServiceCategories } from '../utils/services/settings/contract'
import useNetwork from './useNetwork'

export default function useServiceCategory() {
    const [serviceCategory, setServiceCategory] = useState<ServiceCategory[]>([])
    const isOnline = useNetwork();

    const { refetch } = useQuery(
        ['getServiceCategory'],
        () => fetchServiceCategories(),
        {
            enabled: false,
            retry: 2,
            onSuccess: (response) => {
                setServiceCategory(response.data)
            },
        }
    )

    const getServiceCategory = async () => {
        if (isOnline) {
            refetch()
        } else {
            const serviceCats = await getServiceCategories()
            setServiceCategory(serviceCats)
        }
    }

    return { serviceCategory, getServiceCategory }
}
