import React, { FC, useState } from 'react'
import { AppModal } from '../../UserSettings/Modal'
import { ButtonPrimary } from '../../UIKit/ButtonPrimary/Button'
import {   Navigate, useLocation } from 'react-router-dom'
import InfoIcon from '../../../assets/img/auth/access-info.svg'
import { deleteLocalState, getSavedState } from '../../../utils/localStorage'

type AccessDeniedT = {
    showModal: boolean
}
export const AccessDenied:FC<AccessDeniedT> = (props) => {
    const {showModal} = props
    const GHOSTUser = getSavedState('GHOSTUser')
    const {userName} = GHOSTUser
    const location = useLocation()
    const [shouldLogout, setShouldLogout] = useState(false)

    if(shouldLogout){
        return <Navigate to="/" state={{ path: location.pathname, deniedUser: userName }} />
    }

  return (
    <AppModal
            title=""
            isOpen={showModal}
            setIsOpen={()=> setShouldLogout(true)}
            containerClass="w-full sm:w-80 lg:w-96"
            contentClass="h-auto rounded-lg items-center"
            showCloseButton={false}
            childrenContainer="w-full rounded-lg flex flex-col items-center"
        >
            <div className="bg-[#EFEFEF] w-full flex justify-center items-center h-12">
                <p className="font-bold font-Inter text-[#79828B]">Access Denied</p>
            </div>
            <div className="flex flex-col items-center my-8">
                <img src={InfoIcon} alt="success" />
                <div className="font-Inter font-bold mt-6 p-3">You are currently logged in but you don't have access to this resource. Please login with the appropriate credentials to continue </div>
            </div>
            <div className="mb-10">
                <ButtonPrimary
                    isLoading={false}
                    type="button"
                    label="Ok"
                    handleClick={()=> setShouldLogout(true)}
                />
            </div>
        </AppModal>
  )
}
