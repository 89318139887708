import { devInstance } from '../axios/axiosInstance'
import { cleanObject } from '../cleanObject'
import { createQueryString } from '../createQueryString'

export const fetchStates = async (payload: any) => {
    let filteredPayload = cleanObject(payload)
    var queryString = filteredPayload && createQueryString(filteredPayload)
    const data = await devInstance.get(`passenger?${queryString}`)
    return data
}

export const fetchContractPartyRoles = async () => {
    const response = await devInstance.get('/Setting/GetContractPartyRole')
    return response
}

export const getStates = async (payload: any) => {
    const response = await devInstance.post('/State/Search', payload)
    return response
}

export const fetchBanks = async (payload: any) => {
    const response = await devInstance.post('/Bank/search', payload)
    return response
}

export const fetchEquipmentCategory = async (payload: any) => {
    const response = await devInstance.post('EquipmentCategory/search', payload)
    return response
}

export const fetchAllEquipmentCategory = async (payload: any) => {
    const response = await devInstance.post('EquipmentCategory/getAll', payload)
    return response
}

export const fetchEquipment = async (payload: any) => {
    const response = await devInstance.post('/Equipment/search', payload)
    return response
}

export const fetchEquipmentType = async () => {
    const response = await devInstance.get('/EquipmentType/getAll')
    return response
}

export const fetchCountries = async (payload: any) => {
    const response = await devInstance.post('/Country/Search', payload)
    return response
}

export const fetchFeeTypes = async () => {
    const response = await devInstance.get('/Setting/GetFeeType')
    return response
}

export const fetchTicketClasses = async () => {
    const response = await devInstance.get('/TicketClass/getAll')
    return response
}

export const fetchPassengerTypes = async () => {
    const response = await devInstance.get('/PassengerType/getAll')
    return response
}
export const fetchLocations = async (payload: any) => {
    const response = await devInstance.post('/Location/search', payload)
    return response
}

export const fetchCurrency = async (payload: any) => {
    const response = await devInstance.post('/Currency/Search', payload)
    return response
}

export const fetchOrganizationType = async (payload: any) => {
    const response = await devInstance.get(
        '/Setting/GetOrganizationType',
        payload
    )
    return response
}

export const fetchAirports = async () => {
    const response = await devInstance.get('/Airport/get')
    return response
}

export const getFlightsByOrgId = async (orgId: any) => {
    let orgIds = []
    orgIds.push(orgId)
    const response = await devInstance.get(`/Flight/get/organization/${orgIds.toString()}`)
    return response
}

export const fetchContractScenarios = async (
    ContractServiceId: any,
    PartnerOrganizationId: any
) => {
    const response = await devInstance.get(
        `/Contract/GetContractScenarios?ContractServiceId=${ContractServiceId}&PartnerOrganizationId=${PartnerOrganizationId}`
    )
    return response
}

export const fetchDateTimeKeys = async () => {
    const response = await devInstance.get('/Setting/GetDateTimeKeys')
    return response
}

export const fetchChargeRateTypes = async () => {
    const response = await devInstance.get('/Setting/GetChargeRateType')
    return response
}

export const fetchServiceTypes = async () => {
    const response = await devInstance.get('/ServiceType/getAll')
    return response
}

export const fetchActions = async () => {
    const data = await devInstance.get('/Setting/GetActions')
    return data
}