import { devInstance } from '../axios/axiosInstance'

export const searchAudit = async (payload: any) => {
    const data = await devInstance.post('/AuditTrail/search', payload)
    return data
}

export const downloadAudit = async (payload: any) => {
    const data = await devInstance.post('/AuditTrail/download', payload)
    return data
}

