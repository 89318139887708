import { devInstance } from '../axios/axiosInstance'

export const searchActivityLog = async (payload: any) => {
    const data = await devInstance.post(`ActivityLog/search`, payload)
    return data
}

export const addNewDataEntry = async (payload: any) => {
    const response = await devInstance.post('/DataEntry/Create', payload)
    return response
}

export const updateDataEntry = async (payload: any) => {
    const response = await devInstance.post('/DataEntry/Update', payload)
    return response
}

export const dataEntrySearch = async (payload: any) => {
    const response = await devInstance.post(
        `/DataEntry/Search?Parameter.PageSize=${payload.PageSize}&Parameter.StartDate=${payload.startDate}&Parameter.EndDate=${payload.endDate}&Parameter.Status=${payload.Status}`
    )
    return response
}

export const dataEntryByContractDataId = async (payload: any) => {
    const response = await devInstance.get(
        `/DataReconciliation/GetTwoWayDataEntry?ContractDataId=${payload.contractId}`
    )
    return response
}

export const getSingleEntry = async (entryId: any) => {
    const response = await devInstance.get(
        `/DataEntry/GetSingleItemAsync?id=${entryId}`
    )
    return response
}

export const getAgentsByLocation = async () => {
    const response = await devInstance.get(
        `/UserLocation/GetFieldAgentsInLocation`
    )
    return response
}

export const createEntryChat = async (payload: any) => {
    const response = await devInstance.post(
        `/Chat/Createchat`, payload
    )
    return response
}

export const getEntryChat = async (payload: any) => {
    const response = await devInstance.get(
        `/Chat/GetChats?PageSize=${payload.PageSize}&Page=${payload.Page}&Parameter.Source=${payload.Source}&Parameter.SourceId=${payload.SourceId}`
    )
    return response
}

export const searchDataEntry = async (payload: any) => {
    const response = await devInstance.post('/DataEntry/Search', payload)
    return response
}

export const dataByDateRange = async (payload: any) => {
    const response = await devInstance.post(
        '/DataReconciliation/AggregateComparedDataByDateRange',
        payload
    )
    return response
}

export const dataEntryByDate = async (payload: any) => {
    const response = await devInstance.post(
        '/DataReconciliation/AggregateComparedDataBySingleDate',
        payload
    )
    return response
}

export const initiateReconcilliation = async (payload: any) => {
    const response = await devInstance.post(
        '/DataReconciliation/InitiateDataReconciliation',
        payload
    )
    return response
}

export const searchDataProcessingLog = async (payload: any) => {
    const response = await devInstance.post(
        '/DataProcessing/SearchDataProcessingLog',
        payload
    )
    return response
}

export const uploadFile = async (payload: any) => {
    const formData = new FormData()
    formData.append('file', payload.file)
    const response = await devInstance.post(
        `/DataEntry/Uploadfile/Contract/${payload.contractId}`,
        formData
    )
    return response
}

export const getMyTasks = async (payload: any) => {
    const response = await devInstance.post(
        `/DataEntry/PendingApproval?PageSize=${payload.pageSize}&From=${payload?.page}&Parameter.DueStatus=${payload?.parameter?.dueStatus}&Parameter.StartDate=${payload?.parameter?.startDate}&Parameter.EndDate=${payload?.parameter?.endDate}`,
        {}
    )
    return response
}

export const dataApproval = async (payload: any) => {
    const formData = new FormData()
    formData.append('file', payload.file)
    const response = await devInstance.post(
        `/DataEntry/Approval?ContractId=${payload?.ContractId}&SessionId=${payload?.SessionId}&Comment=${payload?.Comment}&Status=${payload?.Status}`,
        formData
    )
    return response
}
export const bulkDataApproval = async (payload: {
    ContractId?: string
    EntryIds?: string
    Status?: number
}) => {
    const formData = new FormData()
    const response = await devInstance.post(
        `/DataReconciliation/Approval?ContractId=${payload?.ContractId}&EntryIds=${payload?.EntryIds}&Status=${payload?.Status}`,
        formData
    )
    return response
}

export const approveDataReconciliation = async (payload: {
    itemId?: string
    lineItemId?: string
    contractServiceId?: number
}) => {
    const response = await devInstance.post(
        '/DataReconciliation/ApproveDataReconciliation',
        payload
    )
    return response
}

export const cancelDataReconciliation = async (payload: {
    itemId?: string
    lineItemId?: string
    contractServiceId?: number
}) => {
    const response = await devInstance.post(
        '/DataReconciliation/CancelInitatedReconciliation',
        payload
    )
    return response
}
