import { ReactElement, useContext } from 'react'
import GenericLayout from '../../../components/GenericLayout'
import { DataEntryCard } from '../../../components/Settings/DataEntry/DataEntryCard'
import useNetwork from '../../../hooks/useNetwork'
import { getSavedState } from '../../../utils/localStorage'
import { DataCaptureCtx } from '../context/dcContext'

export default function DataEntry(): ReactElement {
    const { allData, setAllData } = useContext(DataCaptureCtx)
    const isOnline = useNetwork()
    const localStorage = isOnline
        ? getSavedState('GHOSTUser')
        : { userRole: 'fieldagent' }
    const { userRole = 'offline' } = localStorage

    return (
        <GenericLayout breadcrumbs={['Data Capture', 'Data Entry']}>
            <div className="flex flex-col w-full">
                <div className=" bg-green-900 relative w-full h-28"></div>
                <div className="bg-white lg:w-4/5 -mt-6 z-10 mx-auto rounded p-9">
                    <div className="text-blue-900 mb-6">Data Capture</div>
                    <div className="py-5 flex flex-wrap justify-left gap-6">
                        {isOnline ? (
                            <>
                                <DataEntryCard
                                    key={342}
                                    contractService={{
                                        name: 'Add New Entries',
                                    }}
                                />
                                {userRole?.toLowerCase() === 'fieldagent' && (
                                    <DataEntryCard
                                        key={343}
                                        contractService={{
                                            name: 'View/Modify Entries',
                                        }}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <DataEntryCard
                                    key={342}
                                    contractService={{
                                        name: 'Add New Entries',
                                    }}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </GenericLayout>
    )
}
