import { FC, useContext, useState } from 'react'
import addEntryIcon from '../../../assets/img/DataCapture/addEntry.svg'
import viewEntry from '../../../assets/img/DataCapture/viewEntry.svg'
import { DataCaptureCtx } from '../../../pages/DataCapture/context/dcContext'
import { AppModal } from '../../UserSettings/Modal';
import { useNavigate, Link } from 'react-router-dom'
import { ScheduledContext } from '../../../pages/DataCapture/GroundHandling/Scheduled/context/scheduledContext'
import { UnscheduledContext } from '../../../pages/DataCapture/GroundHandling/Unscheduled/context/unScheduledContext'

export const DataEntryCard: FC<{
    contractService: any
}> = ({ contractService }) => {
    const navigate = useNavigate();
    const { setAllData, allData } = useContext(DataCaptureCtx)
    const { allDataGHS, setAllDataGHS } = useContext(ScheduledContext)
    const { allDataUns, setAllDataUns } = useContext(UnscheduledContext)
    const [ghsModal, setGHSModal] = useState(false)

    return (
        <>
            <div
                className=" w-64 bg-[#F6F8FA] p-6 py-8 flex flex-col items-center gap-9 cursor-pointer"
                onClick={() => {
                    // if (contractService.name == "Passenger Transfer") {
                    //     setAllData({
                    //         ...allData,
                    //         dataCapture: {
                    //             ...allData.dataCapture,
                    //             selectedContractService: contractService,
                    //         },
                    //     })
                    // }
                    // if (contractService.name == "Ground Handling Service") {
                    //     setAllDataUns({
                    //         ...allDataUns,
                    //         selectedContractService: {
                    //             ...contractService
                    //         },
                    //     })
                    //     setAllDataGHS({
                    //         ...allDataGHS,
                    //         selectedContractService: {
                    //             ...contractService
                    //         },
                    //     })
                    //     setGHSModal(true);
                    // }
                    if (contractService.name == "Add New Entries") {
                        navigate('/data-capture/unscheduled/add')
                    }
                    if (contractService.name == "View/Modify Entries") {
                        navigate('/data-capture/unscheduled')
                    }
                }}
            >
                <img src={contractService.name == "Add New Entries" ? addEntryIcon : viewEntry} alt={contractService.title} />
                <div className="text-center text-[#003B65] font-semibold uppercase">
                    {contractService.name}
                </div>
            </div>
            {ghsModal && (
                <AppModal
                    title=""
                    isOpen={ghsModal !== null}
                    setIsOpen={setGHSModal}
                    containerClass="w-full sm:w-80 lg:w-96"
                    contentClass="py-2 h-auto rounded-lg items-center"
                    showCloseButton={true}
                    childrenContainer="w-full px-4 flex flex-col items-center"
                >
                    <p className='font-bold font-Inter md:text-lg my-6'>What data entry type is this</p>
                    <div 
                        className='bg-[#F9F9F9] w-full flex justify-center rounded-lg py-4 mb-2 cursor-pointer'
                        onClick={() => {
                            setAllDataGHS({
                                ...allDataGHS,
                                entryType: {
                                    name: 'Scheduled',
                                    id: 0
                                },
                            })
                            navigate('/data-capture/data-entry/ground-handling/scheduled')
                        }}
                    >
                        <span className='font-bold font-Inter text-sm'>Scheduled</span>
                    </div>
                    <div 
                        className='bg-[#F9F9F9] w-full flex justify-center rounded-lg py-4 mb-2 cursor-pointer'
                        onClick={() => {
                            setAllDataUns({
                                ...allDataUns,
                                entryType: {
                                    name: 'Unscheduled',
                                    id: 1
                                },
                            })
                            navigate('/data-capture/data-entry/ground-handling/unscheduled')
                        }}
                    >
                        <span className='font-bold font-Inter text-sm'>Unscheduled</span>
                    </div>
                </AppModal>
            )}
        </>
    )
}
