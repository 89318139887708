import { useContext, useRef } from 'react'
import { UiContext } from '../../../../context/uiContext'
import Menu from '../Menu'
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside'
import logo from '../../../../assets/img/logo.svg'

const MobileSidebar = () => {
    const { toggleSidebar, setToggleSidebar } = useContext(UiContext)
    const ref = useRef()
    useOnClickOutside(ref, () => setToggleSidebar(false))

    return (
        <aside
            className={`w-[220px] bg-white opacity-100 lg:hidden shadow min-h-screen top-0 fixed  ${
                toggleSidebar ? 'left-0' : 'left-[-220px]'
            } z-50`}
            //@ts-ignore
            ref={ref}
        >
            {/* <div className="p-4 flex justify-between items-center">
                <div className="w-full flex justify-between items-center">
                    <img src={logo} alt="logo" />
                    <div
                        className="h-6 w-6 flex items-center justify-center mr-0 bg-red-400 rounded-md text-white"
                        onClick={() => setToggleSidebar(false)}
                    >
                        X
                    </div>
                </div>
            </div> */}
            <div className='h-[73px] w-full pl-6 pt-2 border-b'>
                <img src={logo} alt='icon' />
            </div>
            <Menu />
        </aside>
    )
}

export default MobileSidebar
