import { Suspense, lazy } from 'react'
const DataReconcilliation = lazy(
    () => import('../../../pages/DataReconcilliation/DataReconcilliation')
)
const ContractService = lazy(
    () => import('../../../pages/DataReconcilliation/ContractService/Contract')
)
const EntriesByDate = lazy(
    () => import('../../../pages/DataReconcilliation/EntriesByDate/EntriesByDate')
)
const DataReconcilliationDetail = lazy(
    () => import('../../../pages/DataReconcilliation/EntryDetails/EntryDetail')
)

import { getUserPermissions } from '../../../utils/localStorage'

import { IRoute } from './PermissionRoutes'

const permissions = getUserPermissions()

export const dataReconcilliationRoutes: IRoute[] = [
    {
        path: 'reconcilliation-type',
        comp: <ContractService />,
        isAllowed: permissions.has('data_reconcilliation'),
    },
    {
        path: 'reconcilliation/:contractService',
        comp: <DataReconcilliation />,
        isAllowed: permissions.has('data_reconcilliation'),
    },
    {
        path: 'reconcilliation/log/:date',
        comp: <EntriesByDate />,
        isAllowed: permissions.has('data_reconcilliation'),
    },
    {
        path: 'reconcilliation/detail/:contractId',
        comp: <DataReconcilliationDetail />,
        isAllowed: permissions.has('data_reconcilliation'),
    },
]
