import React, { Dispatch, SetStateAction } from 'react'
import { ButtonPrimary } from '../../UIKit/ButtonPrimary/Button'
import { AppModal } from '../../UserSettings/Modal'
import successIcon from '../../../assets/img/TransactionHistory/success.svg'

const ShowSuccesModal = (props: {
    showSuccess: boolean
    setShowConfirmation: any
    setOpenEditAmountModal: any
    setShowReason:any,
    setShowSuccess: Dispatch<SetStateAction<boolean>>
    setEditEntryModal: any
}) => {
    const {
        showSuccess,
        setShowSuccess,
        setOpenEditAmountModal,
        setShowConfirmation,
        setShowReason,
        setEditEntryModal
    } = props
    return (
        <AppModal
            title=""
            isOpen={showSuccess !== null}
            setIsOpen={setShowSuccess}
            containerClass="w-full sm:w-80 lg:w-96"
            contentClass="h-auto rounded-lg items-center"
            showCloseButton={false}
            childrenContainer="w-full rounded-lg flex flex-col items-center"
        >
            <div className="bg-[#EFEFEF] w-full flex justify-center items-center h-12">
                <p className="font-bold font-Inter text-[#79828B]">Success</p>
            </div>
            <div className="flex flex-col items-center my-8">
                <img src={successIcon} alt="success" />
                <p className="font-Inter font-bold mt-6">Approval </p>
                <p className="font-Inter font-bold">Successful!</p>
            </div>
            <div className="mb-10">
                <ButtonPrimary
                    isLoading={false}
                    type="button"
                    label="Done"
                    handleClick={() => {
                        setShowSuccess(false)
                        setShowConfirmation(false)
                        setOpenEditAmountModal(false)
                        setEditEntryModal(false)
                        setShowReason(false)
                    }}
                />
            </div>
        </AppModal>
    )
}

export default ShowSuccesModal
