import * as React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { ButtonOutlined } from '../../../../components/UIKit/ButtonOutlined/Button'
import { ButtonPrimary } from '../../../../components/UIKit/ButtonPrimary/Button'
import { ContractFormCtx } from '../../../../pages/Settings/Contract/context/contractFormCtx'
 
import Services from './Services'

interface Props {
    toggleSettlement: Function
    setFormStep:any
}

export const ServicesRendered: React.FC<Props> = ({ toggleSettlement, setFormStep }) => {
    const { allData, setAllData } = React.useContext(ContractFormCtx)

    const defaultValues = {
        scenarios: Object.keys(allData.scenarios).map(
            (val, index) => allData.scenarios[index]
        ),
    }

    const methods = useForm({
        mode: 'onChange',
        defaultValues,
    })
    const { control, register, handleSubmit, getValues, formState, setValue } =
        methods
    const onSubmit = (data: any) => {
        setAllData({
            ...allData,
            scenarios: {
                ...data.scenarios,
            },
        })
        toggleSettlement()
    }
    const { errors } = formState

    return (
        <div>
            <FormProvider {...methods}> 
                <form onSubmit={handleSubmit(onSubmit)} className="p-6">
                    <Services
                        {...{
                            control,
                            register,
                            defaultValues,
                            getValues,
                            setValue,
                            errors,
                        }}
                    />

                    {/* <input type="submit" disabled={!formState.isValid} /> */}
                    <div className="mt-8 flex gap-4">
                        <ButtonOutlined
                            label="Cancel"
                            type="button"
                            handleClick={toggleSettlement}
                        />
                        <ButtonPrimary type="submit" label="Save" />
                    </div>
                </form>
            </FormProvider>
        </div>
    )
}
