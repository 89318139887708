import React, { ReactElement, useState, useEffect, useContext } from 'react'
import Select from 'react-select'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ButtonOutlined } from '../../../components/UIKit/ButtonOutlined/Button'
import { ButtonPrimary } from '../../../components/UIKit/ButtonPrimary/Button'
import useOperationTypes from '../../../hooks/useOperationType'
// import useEquipByOrgId from '../../../../hooks/useGetEquipByOrgId'
// import useServiceCategory from '../../../../hooks/useServiceCategory'
// import useContractServiceByOrganization from '../../../../hooks/useContractServiceByOrg'
import { useNavigate } from 'react-router-dom'
import { customStyles } from '../../../utils/selectStyles'
import { ScheduledContext } from '../../../pages/DataCapture/GroundHandling/Scheduled/context/scheduledContext'
import { Input } from '../../../components/UIKit/Input/Input'
import useInvoiceStatus from '../../../hooks/useInvoiceStatus'
import useLocationByOrgId from '../../../hooks/useLocationByOrgId'
import { getSavedState } from '../../../utils/localStorage'
import useCurrency from '../../../hooks/useCurrency'

const schema = yup.object().shape({
    address: yup.string().required('Operation Date  is required'),
    startDate: yup.string().required('Start date is required'),
    endDate: yup.string().required('End date is a required field'),
    clientId: yup.string().required('customer is a required field'),
    locationId: yup.string().required('location is a required field'),
    currencyId: yup.string().required('location is a required field'),
})

interface IOperation {
    setFormStep: any
}

export function ClientInfo({ setFormStep }: IOperation): ReactElement {
    const savedState = getSavedState('GHOSTUser')
    const { allDataGHS, setAllDataGHS } = useContext(ScheduledContext)
    const { operationInfo, selectedContractService, entryType } = allDataGHS

    const { operationTypes, fetchAllOperationTypes } = useOperationTypes()
    const { locations, getLocByOrgId } = useLocationByOrgId(
        savedState.organizationId
    )
    const { currency, getAllCurrencies } = useCurrency()
    const { invoiceStatus, getInvoiceStatus } = useInvoiceStatus()
    // const { customers, getCustomers } = usePartners()
    const [customers] = useState([{ name: 'Avitech', id: '1' }])

    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            startDate: '',
            endDate: '',
            address: '',
            clientId: 0,
            locationId: 0,currencyId:0
        },
    })

    const onSubmit = (data: any) => {
        setAllDataGHS({
            ...allDataGHS,
            operationInfo: {
                ...data,
            },
        })
        setFormStep(2)
    }

    const currentValues = getValues()

    useEffect(() => {
        fetchAllOperationTypes()
        getAllCurrencies()
        getLocByOrgId()
    }, [])
    useEffect(() => {
        if (entryType?.name) {
            setValue(
                'operationType',
                {
                    value: entryType?.id,
                    label: entryType?.name,
                },
                {
                    shouldValidate: true,
                }
            )
        }
    }, [entryType?.name])

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="">
            <div className="flex flex-col md:flex-row gap-4 mt-4 flex-wrap justify-between pb-12 border-b">
                <div className="md:mt-4 w-full md:w-[calc(48.5%)]">
                    <label className="font-Inter">Client</label>

                    <Select
                        placeholder="Select"
                        options={customers?.map((item: any) => {
                            return {
                                value: item.id,
                                label: item?.name,
                            }
                        })}
                        onChange={(newValue: any) =>
                            setValue('clientId', newValue.value)
                        }
                        // defaultValue={values.industry}
                        styles={customStyles}
                    />

                    <p className="text-xs text-red-600">
                        {errors?.cliendId?.value?.message}
                    </p>
                </div>
                <div className="md:mt-4 w-full md:w-[calc(48.5%)]">
                    <label className="font-Inter">Address</label>
                    <div className="w-full">
                        <Input
                            placeholder="Address"
                            loading={false}
                            register={register('address')}
                            name="address"
                            type="date"
                        />
                    </div>
                    <p className="text-xs text-red-600">
                        {errors?.address?.message}
                    </p>
                </div>
                <div className="md:mt-4 w-full md:w-[calc(48.5%)]">
                    <label className="font-Inter">Start Date</label>
                    <div className="w-full">
                        <Input
                            placeholder="Start Date"
                            loading={false}
                            register={register('startDate')}
                            name="startDate"
                            type="date"
                        />
                    </div>
                    <p className="text-xs text-red-600">
                        {errors?.startDate?.message}
                    </p>
                </div>
               
                <div className="md:mt-4 w-full md:w-[calc(48.5%)]">
                    <label className="font-Inter">End Date</label>
                    <div className="w-full">
                        <Input
                            placeholder="End Date"
                            loading={false}
                            register={register('endDate')}
                            name="endDate"
                            type="date"
                        />
                    </div>
                    <p className="text-xs text-red-600">
                        {errors?.endDate?.message}
                    </p>
                </div>
                <div className="md:mt-4 w-full md:w-[calc(48.5%)]">
                    <label className="font-Inter">Currency</label>

                    <Select
                        placeholder="Select"
                        options={currency?.map((item: any) => {
                            return {
                                value: item.id,
                                label: item?.name,
                            }
                        })}
                        onChange={(newValue: any) =>
                            setValue('currencyId', newValue.value)
                        }
                        // defaultValue={values.industry}
                        styles={customStyles}
                    />

                    <p className="text-xs text-red-600">
                        {errors?.currencyId?.value?.message}
                    </p>
                </div>
            </div>
            
            <div className="flex justify-center md:justify-end gap-4 mt-4 md:pr-6">
                <ButtonOutlined
                    type="button"
                    label="Cancel"
                    handleClick={() => navigate(-1)}
                />
                <ButtonPrimary
                    isLoading={false}
                    type="submit"
                    label="Next"
                    // handleClick={() => setFormStep(2)}
                />
            </div>
        </form>
    )
}
