import { getSavedState } from '../localStorage'
import { fetchFlightByOrgId } from '../services/flightSchedule'
import { db } from './localbase'
import { Flight } from './localbase.types'

const OFFLINE_ORG = getSavedState('OFFLINE_ORG')

export async function setupFlights(partnerIds: any[]) {

    //fetch and persist flights
    const flightsResp = await fetchFlightByOrgId(partnerIds)
    if (flightsResp.data) {
        try {
            await db.orgFlights.bulkAdd(flightsResp.data)
        } catch (ex) {
            console.log('error in bulk add flights', ex)
        }
    }
}

export async function getFlightsByOrg() {
    let flights:Flight[] = []
    try {
        flights = await db.orgFlights.toArray()
    } catch (error) {
        console.log('Error reading flights', error)
    }
    return flights
}
