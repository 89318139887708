import { useState } from 'react'
import { useQuery } from 'react-query'
import { fetchBanks } from '../utils/services/utils'

export default function useBanks() {
    const [banks, setBanks] = useState([])

    const payload = {
        pageSize: 20,
        from: 1,
        parameter: {},
    }

    const { refetch } = useQuery(['GetAllBanks'], () => fetchBanks(payload), {
        enabled: true,
        retry: 2,
        onSuccess: (response) => {
            setBanks(response.data.resultList)
        },
    })

    const getAllBanks = () => {
        refetch();
    }

    return { banks, getAllBanks }
}
