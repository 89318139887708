import React, { createContext, useState } from 'react'

export const defaultValue = {
    operationInfo: {
        operationType: {
            value: '',
            label: '',
        },
        operationDate: '',
        airline: {
            value: '',
            label: '',
            contractId: 0,
            shortName: '',
            organizationTypeId: 0,
            address: ''
        },
        aircraftCategory: {
            value: '',
            label: '',
        },
        handlingType: {
            value: '',
            label: '',
        },
    },
    selectFlight: {
        eta: '',
        etd: '',
        airCraftRegNo: '',
        aircraftType: {
            value: 0,
            label: '',
        },
        departureLocation: {
            value: 0,
            label: '',
        },
        departureFlightNumber: {
            value: 0,
            label: '',
        },
        arrivalLocation: {
            value: 0,
            label: '',
        },
        arrivalFlightNumber: {
            value: 0,
            label: '',
        },
    },
    contractId: '',
    serviceRendered: {
        metadata: [{
            serviceType: '',
            amount: 0,
            servicePeriod: 0,
            servicePeriodHourlyStart: null,
            servicePeriodHourlyEnd: null,
        }],
        comment: ''
    },
    selectedContractService: {
        activeContracts: [],
        code: '',
        description: 'hope',
        icon: '',
        id: 0,
        name: '',
    },
    entryType: {
        name: 'UnScheduled',
        id: 1
    },
    equipByOrgId: [],
    anotherId: ''
}

interface IContext {
    operationInfo: IOperationInfo
    selectFlight: ISelectFlight
    serviceRendered: IseviceRendered
    equipByOrgId: any
    selectedContractService: ISelectedContractService
    contractId: string
    selectedEntryId?: string
    entryType: IEntryType
    anotherId: any
}
interface IData {
    allDataUns: IContext
    setAllDataUns: Function
}
interface IOperationInfo {
    operationType: {
        value: string
        label: string
    }
    operationDate: string
    airline: {
        value: string
        label: string
        contractId: number,
        shortName: string,
        organizationTypeId: number,
        address: string
    }
    aircraftCategory: {
        value: string
        label: string
    }
    handlingType: {
        value: string
        label: string
    }
    id?: number
}
interface ISelectFlight {
    eta: string,
    etd: string,
    airCraftRegNo: string,
    aircraftType: {
        value: number,
        label: string,
    },
    departureLocation: {
        value: number,
        label: string,
    },
    departureFlightNumber: {
        value: number,
        label: string,
    },
    arrivalLocation: {
        value: number,
        label: string,
    },
    arrivalFlightNumber: {
        value: number,
        label: string,
    },
}
interface IMetadaData {
    serviceType: string,
    amount: number,
    servicePeriod: number,
    servicePeriodHourlyStart: any,
    servicePeriodHourlyEnd: any
    AircraftCategory?: string
    AircraftSeries?: string
    AircraftType?: string
}
interface IseviceRendered {
    metadata: IMetadaData[],
    comment: string
}
interface IEntryType {
    name: string
    id: number
}
interface ISelectedContractService {
    activeContracts: any[]
    code: string
    description: string
    icon: string
    id: number
    name: string
}

export const UnscheduledContext = createContext<IData>({
    allDataUns: defaultValue,
    setAllDataUns: () => {},
})

export function UnscheduledProvider({ children }: any) {
    const [allDataUns, setAllDataUns] = useState(defaultValue)
    return (
        <UnscheduledContext.Provider value={{ allDataUns, setAllDataUns }}>
            {children}
        </UnscheduledContext.Provider>
    )
}
