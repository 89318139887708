import { fetchServiceCategories } from '../services/settings/contract'
import { db } from './localbase'

export async function setupServiceCategories() {
    //fetch and persist service categories
    const serviceCategoryResp = await fetchServiceCategories()    
    if (serviceCategoryResp.data) {
        await db.serviceCategories.bulkAdd(serviceCategoryResp.data)
    }
}

export async function getServiceCategories() {
    const serviceCats = await db.serviceCategories.toArray()
    return serviceCats
}
