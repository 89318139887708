import { devInstance } from '../../axios/axiosInstance'

export const addNewContract = async (payload: any) => {
    const response = await devInstance.post('/Contract/Save', payload)
    return response
}

export const fetchSettlementTypes = async () => {
    const response = await devInstance.get('/Setting/GetSettlementType')
    return response
}

export const fetchSettlementCycles = async () => {
    const response = await devInstance.get('/Setting/GetSettlementCycle')
    return response
}

export const fetchInvoiceCycles = async () => {
    const response = await devInstance.get('/Setting/GetInvoiceCycle')
    return response
}

export const searchContractSettings = async (payload: any) => {
    const data = await devInstance.post('/Contract/SearchBasic', payload)
    return data
}

export const getContractById = async (id: string) => {
    const data = await devInstance.get(`/Contract/GetById?id=${id}`)
    return data
}

export const deactivateContract = async (contractId: any) => {
    const data = await devInstance.post(`/Contract/DeActivate?id=${contractId}`)
    return data
}

export const activateContract = async (contractId: any) => {
    const data = await devInstance.post(`/Contract/Activate?id=${contractId}`)
    return data
}

export const fetchServiceCategories = async () => {
    const response = await devInstance.get('/Setting/GetServiceCategory')
    return response
}
