import { createContext, useState } from 'react'

export interface ICase {
    AircraftCategory: any
    AircraftType: any
    AircraftSeries: any
    ServiceType: any
    ChargeRateTypeId: any
    Rate: any
    FreePeriod: any
    Interval: any
}

interface IScenario {
    Name: any
    FeeTypeId: any
    Cases: Array<ICase>
}

const defaultValue = {
    id: '',
    serviceType: '',
    contractFileUrl: '',
    contractInfo: {
        contractTitle: '',
        ContractDescription: '',
    },
    participants: {
        homeCountry: '',
        organization: '',
        airlineDataCapture: '',
    },
    tenor: {
        tenorStartDate: '',
        tenorEndDate: '',
    },
    scenarios: [
        {
            Name: '',
            FeeTypeId: '',
            Cases: [
                {
                    AircraftCategory: undefined,
                    AircraftType: undefined,
                    AircraftSeries: undefined,
                    ServiceType: undefined,
                    ChargeRateTypeId: undefined,
                    Rate: undefined,
                    FreePeriod: undefined,
                    Interval: undefined,
                },
            ],
        },
    ],
    settlement: {
        invoivceCycleId: '',
        invoiceCurrency: '',
        invoicingStartDate: '',
        vat: '',
        autoInvoicing: '',
    },
    formStep: 0,
}

interface IContractInfo {
    contractTitle: string
    ContractDescription: string
}

interface IParticipants {
    homeCountry: any
    organization: any
    airlineDataCapture: any
}

interface ITenor {
    tenorStartDate: string
    tenorEndDate: string
}

interface ISettlement {
    invoivceCycleId: any
    invoicingStartDate: string
    invoiceCurrency: any
    vat: string
    autoInvoicing: any
}

interface IContext {
    id: string
    serviceType: string
    contractFileUrl: string
    contractInfo: IContractInfo
    participants: IParticipants
    tenor: ITenor
    scenarios: Array<IScenario>
    settlement: ISettlement
    formStep: number
}

interface IData {
    allData: IContext
    setAllData: Function
    resetData: Function
}

export const ContractFormGHS = createContext<IData>({
    allData: defaultValue,
    setAllData: () => {},
    resetData: () => {},
})

export function ContractFormGHSProvider({ children }: any) {
    const [allData, setAllData] = useState(defaultValue)
    const resetData = () => {
        setAllData(defaultValue)
    }
    return (
        <ContractFormGHS.Provider value={{ allData, setAllData, resetData }}>
            {children}
        </ContractFormGHS.Provider>
    )
}
