import React, { FC, useState } from 'react'
import { SideModal } from '../../Modals/SideModal'
import { ButtonPrimary } from '../../UIKit/ButtonPrimary/Button'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { ErrorCard } from '../../UIKit/errorCard'
import { CardList } from './chartCard'
import { EmptyState } from '../../UIKit/EmptyState/EmptyState'
import { getSavedState } from '../../../utils/localStorage'

export const SideChat: FC<{
    sideModal: any
    setSideModal: any
    chatData: any
    refetchGetChat: any
    createChat: any
    contractDataId: any
    createChatLoading: boolean
    chatLoading: boolean
    isError: any
}> = ({ sideModal, setSideModal, chatData, refetchGetChat, createChat, contractDataId, createChatLoading, chatLoading, isError }) => {
    const [comment, setComment] = useState<any>('')
    const localStorage = getSavedState('GHOSTUser');
    const { userRole, organizationId } = localStorage;
    return (
        <SideModal isOpen={sideModal} setIsOpen={setSideModal}>
            <div className="pt-4 px-6 h-full">
                <p className="text-[#002555] font-bold text-lg mb-3">
                    Dispute Comments
                </p>
                <p className="mb-2">Add comment</p>
                <textarea
                    placeholder="Enter Comment"
                    className="border border-[#EBEBEB] w-full h-36 rounded-md mb-1 p-4 text-[#999999]"
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                />
                <div className="mb-6">
                    <ButtonPrimary
                        isLoading={createChatLoading}
                        type="button"
                        label="Send"
                        handleClick={() => {
                            if (comment.length < 3) {
                                toast('Comment cannot not be less that 3 characters')
                                return;
                            }
                            createChat({
                                sourceId: contractDataId,
                                comment,
                                source: 1,
                                userOrganizationId: organizationId,
                            })
                            setComment("");
                            refetchGetChat();
                        }}
                    />
                </div>
                {chatLoading && (
                    <div className="pt-8">
                        <Skeleton className="px-5 py-3 my-2 h-8" count={5} />
                    </div>
                )}
                {isError && <ErrorCard />}
                {chatData?.data?.items.length === 0 && (
                    <div className="mt-8">
                        <EmptyState />
                    </div>
                )}
                {chatData?.data?.items.length > 0 && (
                    <div className='text h-[50vh] overflow-y-auto'>
                        <div className="pb-12">
                            <CardList data={chatData?.data?.items} />
                        </div>
                    </div>
                )}
                
            </div>
        </SideModal>
    )
}
