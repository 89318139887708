// db.ts
import Dexie, { Table } from 'dexie'

import { setupContractServiceByOrg } from './airlineOrg'
import { setupEquipments } from './equipment'
import { setupFlights } from './flights'
import { setupOperationTypes } from './operationTypes'
import { setupServiceCategories } from './serviceCategory'
import { bulkAddContractById } from './contractById'
import {
    Partner,
    OperationType,
    ServiceCategory,
    Equipment,
    Flight,
    Contract,
    DataCapture,
    Agent
} from './localbase.types'
import { setupAgentsByLocation } from './agents'
import { toast } from 'react-toastify'

export class MySubClassedDexie extends Dexie {
    airlineOrg!: Table<Partner>
    operationTypes!: Table<OperationType>
    serviceCategories!: Table<ServiceCategory>
    equipments!: Table<Equipment>
    orgFlights!: Table<Flight>
    contractById!: Table<Contract>
    offlineDataCapture!: Table<DataCapture>
    agents!: Table<Agent>

    constructor() {
        super('ghostDB')
        this.version(1).stores({
            airlineOrg:
                'shortName,contractId,organizationId,organizationName,organizationTypeId,shortName',
            operationTypes: 'id,name',
            serviceCategories:
                'id,name,equipmentCategoryId,equipmentCategory,organizationId,organizationName,equipmentTypeId,equipmentType,equipmentNumber,seriesOrModel,status',
            equipments:
                'id,name,equipmentCategoryId,equipmentCategory,organizationId,organizationName,equipmentTypeId,equipmentType,equipmentNumber,seriesOrModel,status',
            orgFlights:
                'id,organizationId,organization,flightNumber,sourceAirportId,sourceAirport,sourceAirportCode,destinationAirportId,destinationAirport,destinationAirportCode,active,isLocal',
            contractById: 'id,contractType,contractTypeName,invoiceCycle,invoiceCycleName,status,termsAndConditions,contractTitle,contractDescription,companyName,createdByName,settlementCurrency,settlementCurrencyId,metadata,metaDataDetail,contractFileUrl,tenorStartDate,tenorEndDate,invoicingStartDate,isActive,vat,organizations',
            offlineDataCapture: 'dataId,ContractId,ProducerId,ScenerioCaseName,UploadedFilePath,SourceId,RecordCount,MetaData,ArrivalFlightNumber,DepartureFlightNumber,ArrivalLocation,DepartureLocation,OperationType,Comment,ETA,ETD,OperationDate,airlineCode,offlineAgent,consumerName,producerName,aircraft',
            agents: 'userId,locationId,userName,locationName,role,roleId'
        })
    }
}

export const db = new MySubClassedDexie()

export async function setupLocalbaseDb() {
    // await db.operationTypes.clear()
    // await db.orgFlights.clear()
    // await db.airlineOrg.clear()
    // await db.serviceCategories.clear()
    // await db.equipments.clear()
    reInitializeLocalDb()
}

const reInitializeLocalDb = async () => {
    setupContractServiceByOrg()
    setupOperationTypes()
    setupServiceCategories()
    setupEquipments()
    bulkAddContractById()
    setupAgentsByLocation()
}
