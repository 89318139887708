import { AppModal } from '../../UserSettings/Modal'
import approveIcon from '../../../assets/img/TransactionHistory/approval.svg'
import { ButtonPrimary } from '../../UIKit/ButtonPrimary/Button'
import { ButtonOutlined } from '../../UIKit/ButtonOutlined/Button'
import { FC } from 'react'

type ConfirmT = {
    showApproveModal: boolean
    setShowApproveModal: any
    setShowDetailsModal: any
    acceptHandler: any
    airlineAccepting: any
}
const Confirm: FC<ConfirmT> = (props) => {
    const {
        setShowApproveModal,
        showApproveModal,
        setShowDetailsModal,
        acceptHandler,
        airlineAccepting
    } = props
    return (
        <AppModal
            title=""
            isOpen={showApproveModal}
            setIsOpen={setShowApproveModal}
            containerClass="w-full sm:w-80 lg:w-[400px]"
            contentClass="h-auto rounded-lg items-center"
            showCloseButton={false}
            childrenContainer="w-full rounded-lg flex flex-col items-center"
        >
            <div className="bg-[#EFEFEF] w-full flex justify-center items-center h-12">
                <p className="font-bold font-Inter text-[#79828B]">Dialog</p>
            </div>
            <div className="flex flex-col items-center my-8">
                <img src={approveIcon} alt="success" />
                <p className="mt-4 font-Inter font-bold px-12 text-center">
                    Are you sure you want to go ahead with this operation
                </p>
                <p className="font-Inter mt-2 mb-8 px-16 text-center text-[#787885] text-xs">
                    This includes all matched and unmatched entries
                </p>
            </div>
            <div className="mb-10 gap-6 flex justify-between">
                <ButtonOutlined
                    isLoading={false}
                    type="button"
                    label="Cancel"
                    handleClick={() => {
                        setShowApproveModal(false)
                        setShowDetailsModal(true)
                    }}
                />
                <ButtonPrimary
                    isLoading={airlineAccepting}
                    type="button"
                    label="Confirm"
                    handleClick={acceptHandler}
                />
            </div>
        </AppModal>
    )
}

export default Confirm
