import { getAgentsByLocation } from '../services/dataEntry'
import { db } from './localbase'

//fetch and perist agents
export async function setupAgentsByLocation() {
    const agentsByLocationResponse = await getAgentsByLocation()
    if (agentsByLocationResponse?.data) {
        try {
            await db.agents.bulkAdd(agentsByLocationResponse?.data)
        } catch (ex) {}
    }
}

export async function getAgents() {
    const locationAgents = await db.agents.toArray()
    return locationAgents
}

export async function deleteAgents() {
    const deletedAgents = await db.agents.clear()
}
