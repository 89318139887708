import { FC } from 'react'
import { AppModal } from '../../../components/UserSettings/Modal'
import happyIcon from '../../../assets/img/TransactionHistory/happy.svg'
import textIcon from '../../../assets/img/TransactionHistory/aA.svg'
import fileIcon from '../../../assets/img/TransactionHistory/file.svg'
import { ButtonPrimary } from '../../../components/UIKit/ButtonPrimary/Button'
import { ButtonOutlined } from '../../../components/UIKit/ButtonOutlined/Button'
import { toast } from 'react-toastify'
import { getSavedState } from '../../../utils/localStorage'

type ReasonModalT = {
    setShowReason: any
    showReason: any
    reasonText: any
    setreasonText: any
    setShowDetailsModal: any
    declineAsync: any
    airlineDeclineAsync: any
    isloadingDecline: boolean
    entryDetail: any
    selectedDisputeType: any
    declineHandler: any
}
const ReasonModal: FC<ReasonModalT> = (props) => {
    const {
        setShowReason,
        showReason,
        setreasonText,
        reasonText,
        setShowDetailsModal,
        declineAsync,
        airlineDeclineAsync,
        isloadingDecline,
        entryDetail,
        selectedDisputeType,
        declineHandler
    } = props

    const userToken = getSavedState('GHOSTUser') || {}
    const { organizationTypeId } = userToken



    const closeReasonModal = () => {
        setreasonText('')
        setShowReason(false)
        setShowDetailsModal(true)
    }

    return (
        <AppModal
            title=""
            isOpen={showReason}
            setIsOpen={setShowReason}
            containerClass="w-full sm:w-80 lg:w-96"
            contentClass="h-auto rounded-lg items-center"
            showCloseButton={false}
            childrenContainer="w-full rounded-lg flex flex-col p-6"
        >
            <div className="flex justify-between">
                <p className="font-Inter font-bold text-[#6f6d6d]">
                    Reason for Reconcilliation
                </p>
                <span
                    className="font-bold text-[#ccc] cursor-pointer"
                    onClick={closeReasonModal}
                >
                    X
                </span>
            </div>
            <div className="flex flex-col mt-8">
                <textarea
                    placeholder="Kindly state your reason for disputing this entry"
                    className="h-48 rounded-md border-[#1B5BBC]"
                    onChange={(e) => setreasonText(e.target.value)}
                    value={reasonText}
                />
            </div>
            <div className="flex justify-end gap-3 my-3">
                <img src={textIcon} alt="text" />
                <img src={happyIcon} alt="happy" />
                <img src={fileIcon} alt="file" className="w-2" />
            </div>
            <div className="mb-4 gap-6 flex justify-between">
                <ButtonOutlined
                    isLoading={false}
                    type="button"
                    label="Back"
                    handleClick={closeReasonModal}
                />
                <ButtonPrimary
                    isLoading={isloadingDecline}
                    type="button"
                    label="Send"
                    handleClick={declineHandler}
                />
            </div>
        </AppModal>
    )
}

export default ReasonModal
