export const timeDifference = (endDate: string, startDate: string, showMin=true) => {
    const unixendDate = new Date(endDate).getTime()
    const unixstartDate = new Date(startDate).getTime()
    // get total seconds between the times
    let delta = Math.abs(unixendDate - unixstartDate) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    const seconds = delta % 60;  // in theory the modulus is not required

    return showMin? `${days}days:${hours}Hrs:${minutes}mins:${seconds}sec`: `${days}days:${hours}Hrs`
}
export const timeDifferenceMin = (endDate: string, startDate: string, showMin=true) => {
    const unixendDate = new Date(endDate).getTime()
    const unixstartDate = new Date(startDate).getTime()
    // get total seconds between the times
    let delta = Math.abs(unixendDate - unixstartDate) / (1000 * 60);

    return showMin && `${delta} mins`
}