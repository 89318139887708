import React, { FC } from 'react'
import { AppModal } from '../../UserSettings/Modal'
import successIcon from '../../../assets/img/TransactionHistory/success.svg'
import { ButtonPrimary } from '../../UIKit/ButtonPrimary/Button'

type TransactionEmailSuccessT = {
    setShowSuccess: any
    showSuccess: boolean
    closeApproval:any

}
const TransactionEmailSuccess: FC<TransactionEmailSuccessT> = (props) => {
    const { showSuccess, setShowSuccess, closeApproval } = props
    return (
        <AppModal
            title=""
            isOpen={showSuccess}
            setIsOpen={setShowSuccess}
            containerClass="w-full sm:w-80 lg:w-96"
            contentClass="h-auto rounded-lg items-center"
            showCloseButton={false}
            childrenContainer="w-full rounded-lg flex flex-col items-center"
        >
            <div className="bg-[#EFEFEF] w-full flex justify-center items-center h-12">
                <p className="font-bold font-Inter text-[#79828B]">Success</p>
            </div>
            <div className="flex flex-col items-center my-8">
                <img src={successIcon} alt="success" />
                <p className="font-Inter font-bold mt-6">Action</p>
                <p className="font-Inter font-bold">Successful!</p>
            </div>
            <div className="mb-10">
                <ButtonPrimary
                    isLoading={false}
                    type="button"
                    label="Done"
                    handleClick={() => {
                        closeApproval()
                    }}
                />
            </div>
        </AppModal>
    )
}

export default TransactionEmailSuccess
