import { FC, useContext, useEffect } from 'react'
import { UiContext } from '../../context/uiContext'
import AdminContent from './Content'
import { AdminHeader } from './Header'
import AdminSidebar from './Sidebar'
import MobileSidebar from './Sidebar/Mobile/Mobile'
import { setupLocalbaseDb } from '../../utils/localbase/localbase'
import { getSavedState, saveLocalState } from '../../utils/localStorage'
import { toast } from 'react-toastify'

interface Props {
    breadcrumbs?: any
}

const GenericLayout: FC<Props> = ({ children, breadcrumbs }) => {
    const GHOSTUser = getSavedState('GHOSTUser')
    const { showDropDown, setShowDropDown, leaveOpen } = useContext(UiContext)

    const offlineSync = getSavedState('offlineSync');

    const handleLayoutClick = () => {
        if (showDropDown && leaveOpen) {
            setShowDropDown(null)
        }
    }

    useEffect(() => {
        if (Object.keys(offlineSync).length == 0) {
            (async () => {
                await setupLocalbaseDb()
                saveLocalState('offlineSync', {status: true})
                if (GHOSTUser.userRole === 'FieldAgent') {
                    toast.success('Pulled data from server for offline capture successfully')
                }
            })()
        }
    }, [offlineSync])

    return (
        <main
            className="flex relative h-screen bg-[#F1F2F3] w-screen overflow-x-hidden"
            onClick={handleLayoutClick}
        >
            <MobileSidebar />
            <AdminSidebar />
            <AdminContent>
                <AdminHeader breadcrumbs={breadcrumbs} />
                {children}
            </AdminContent>
        </main>
    )
}

export default GenericLayout
